<template>
  <div class="--fundo">
    <v-container>
      <div>
        <v-row class="d-flex flex-column">
          <v-col
            cols="12"
            md="12"
            class="d-flex flex-column justify-center text-center"
          >
            <h2 v-html="$t('secondsection.title')" class="--h2"></h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-md-row flex-column justify-space-between"
          >
            <v-card elevation="0" class="pa-5 mb-2">
              <v-card-text>
                <v-icon large color="#d9b335">mdi-account-plus</v-icon>
                <h2 v-html="$t('secondsection.cardonetitle')" class="--h3"></h2>
                <p v-html="$t('secondsection.cardonesubtitle')" class="--p"></p>
                <v-btn @click="dialog = true" color="#14A3A3" block>
                  <span class="mr-2 --bt" v-html="$t('header.button')"></span>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card elevation="0" class="pa-5 mb-2">
              <v-card-text>
                <v-icon large color="#1445a6">mdi-wallet</v-icon>
                <h2 v-html="$t('secondsection.cardtwotitle')" class="--h3"></h2>
                <p v-html="$t('secondsection.cardtwosubtitle')" class="--p"></p>
                <v-btn @click="dialog = true" color="#14A3A3" block>
                  <span class="mr-2 --bt" v-html="$t('header.button')"></span>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card elevation="0" class="pa-5 mb-2">
              <v-card-text>
                <v-icon large color="#1ab873">mdi-store</v-icon>
                <h2
                  v-html="$t('secondsection.cardthreetitle')"
                  class="--h3"
                ></h2>
                <p v-html="$t('secondsection.cardthreesubtitle')" class="--p"></p>
                <v-btn @click="dialog = true" color="#14A3A3" block>
                  <span class="mr-2 --bt" v-html="$t('header.button')"></span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-md-row flex-column justify-space-between"
          >
            <v-card elevation="0" class="pa-5 mb-2">
              <v-card-text>
                <v-icon large color="#d9b335">mdi-star-shooting</v-icon>
                <h2
                  v-html="$t('secondsection.cardfourtitle')"
                  class="--h3"
                ></h2>
                <p v-html="$t('secondsection.cardfoursubtitle')" class="--p"></p>
                <v-btn @click="dialog = true" color="#14A3A3" block>
                  <span class="mr-2 --bt" v-html="$t('header.button')"></span>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card elevation="0" class="pa-5 mb-2">
              <v-card-text>
                <v-icon large color="#1ab873">mdi-phone</v-icon>
                <h2
                  v-html="$t('secondsection.cardfivetitle')"
                  class="--h3"
                ></h2>
                <p v-html="$t('secondsection.cardfivesubtitle')" class="--p"></p>
                <v-btn @click="dialog = true" color="#14A3A3" block>
                  <span class="mr-2 --bt" v-html="$t('header.button')"></span>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card elevation="0" class="pa-5 mb-2">
              <v-card-text>
                <v-icon large color="red">mdi-youtube</v-icon>
                <h2 v-html="$t('secondsection.cardsixtitle')" class="--h3"></h2>
                <p v-html="$t('secondsection.cardsixsubtitle')" class="--p"></p>
                <v-btn @click="dialog = true" color="#14A3A3" block>
                  <span class="mr-2 --bt" v-html="$t('header.button')"></span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center --btn"> </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="1000">
          <PopupForm />
        </v-dialog>
      </div>
    </v-container>
  </div>
</template>

<script>
import PopupForm from "../forms/PopupForm.vue";

export default {
  components: {
    PopupForm,
  },

  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
.--h2 {
  font-weight: 700;
  font-size: 35px;
  padding-bottom: 4rem;
  color: #094949;
}
.--h3 {
  font-weight: 600;
  font-size: 25px;
  color: #094949;
  line-height: 2rem;
  padding-top: 2rem;
}
.--h4 {
  font-weight: 600;
  font-size: 30px;
  color: #094949;
}
.--p {
  padding-top: 2rem;
  font-size: 16px;
  color: #888888;
}
.--bt {
  color: #ffffff;
}
.--form {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.--btn {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.--fundo {
  background-image: url(../../assets/cover-second-section.png);
  background-size: 100%;
}
</style>
