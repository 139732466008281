<template>
    <v-form>
          <v-card class="pa-6">
            <div class="d-flex justify-center align-center">
              <p class="--subtitle">{{ $t("dialog.stepfourtitle") }}</p>
            </div>
            <v-row class="d-flex flex-column">
              <v-col>
                <v-textarea
                  v-model="description"
                  clearable
                  background-color="white"
                  placeholder="Digite aqui..."
                  outlined
                  block
                  dense
                  @keyup="setDescription(description)"
                ></v-textarea>
                <p
                  v-html="$t('dialog.stepfourdescription')"
                  class="--p mt-n4"
                ></p>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <div class="pa-2">
                <v-btn color="#333333" outlined @click="dialog = false">
                  <span style="color: #333333">Fechar</span>
                </v-btn>
              </div>
              <div class="pa-2">
                <v-btn
                  :loading="loading"
                  color="#1ab873"
                  dark
                  @click="requestResponse()"
                >
                  Continuar
                </v-btn>
              </div>
            </v-row>
          </v-card>
    </v-form>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import { getAuth } from "firebase/auth";
  import { required, url } from "vuelidate/lib/validators";
  import { collection, query, getDocs} from "firebase/firestore";
  
  export default {
    name: "DescriptionForm",
  
    data() {
      return {
        description: "",
        dialog: true,
        loading: false,
        resposta: "",
        rules: {
          required: (value) => !!value || "Obrigatório.",
          min: (v) => v.length >= 6 || "Min 6 caractéres",
          min14: (v) => v.length >= 18 || "Min 14 números",
          min11: (v) => v.length >= 14 || "Min 11 números",
          emailMatch: () => `O email e a senha não são compatíveis`,
          counter: (value) => value.length <= 20 || "Max 20 caractéres",
          confirmRule(password) {
            return (v) => password == v || "As senhas não estão iguais";
          },
          email: (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "E-mail inválido";
          },
        },
        validations: {
          website: {
            required,
            url,
          },
        },
      };
    },
  
    computed: {},
  
    async mounted() {
      const q = query(collection(this.$db, "add"));
      const auth = getAuth();
      const user = auth.currentUser;
      console.log('User Auth', user )
  
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
    },
  
    methods: {
      ...mapActions("googleAds", [
        "setDescription",
        "setAdvertises",
      ]),

  
      async requestResponse() {
        this.loading = true;
       
        try {
          await this.setAdvertises({
            companyname: this.companyname,
            description: this.description,
          }).then(() => {
            //setTimeout(() => {
              this.loading = false;
              this.$emit("popupform:close");
             
                this.$router.push("/advertises");
            
           // }, 12000);
          });
        } catch (error) {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
          } else {
            console.log(error.message);
          }
        }
      },
  
      openLoading() {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.stepper = 3;
        }, 5000);
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .--p {
    font-size: 12px;
    color: #221b15;
  }
  .--h2 {
    font-size: 20px;
    color: #000000;
  }
  .--title {
    font-size: 20px;
    font-weight: 700;
    color: #221b15;
  }
  .--subtitle {
    font-size: 20px;
    font-weight: 600;
    color: #221b15;
  }
  </style>
  