<template>
  <div>

  <!-- Desktop -->

  <div class="d-none d-sm-flex flex-column"
  style="margin-left: 10rem; margin-right: 10rem">
    <v-form>
      <v-card elevation="0">
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center mt-4">
            <h2 class="--h1">
              Para prosseguir, você precisa acessar a sua conta.
            </h2>
            <br />
            <p class="--ptext">Acesse ou crie uma conta abaixo</p>
          </div>
          <template>
            <v-card class="d-flex justify-center" elevation="0">
              <v-card-text>
                <div class="d-flex justify-center">
                  <h3>Acessar Conta</h3>
                </div>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="email"
                  class="mt-2"
                  outlined
                  background-color="white"
                  dense
                  clearable
                  label="E-mail"
                >
                </v-text-field>
                <v-text-field
                  :rules="[rules.required]"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  outlined
                  background-color="white"
                  dense
                  clearable
                  label="Senha"
                >
                </v-text-field>
                <v-btn
                  block
                  :loading="loading"
                  color="#1ab873"
                  @click="logIn()"
                >
                  <span style="color: white">Acessar</span>
                </v-btn>
                <template>
                  <div class="d-flex justify-center mt-4">
                    <a
                      ><span style="color: black"
                        ><u>Esqueci a senha</u></span
                      ></a
                    >
                  </div>
                </template>
                <v-btn
                  class="mt-6"
                  outlined
                  block
                  color="black"
                  @click="createAccount()"
                >
                  Criar nova conta
                </v-btn>
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-form>

    <v-dialog v-model="error" persistent max-width="300">
      <v-card>
        <v-img contain max-height="30" width="100%"> </v-img>
        <!-- src="https://lion-web-site.s3.amazonaws.com/public/canal-do-midia.svg" -->
        <v-card-title>Falha no login</v-card-title>
        <v-card-text>Revise os dados inseridos e tente novamente</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="error = false">
            Tentar novamente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <!-- Mobile -->
  <div class="d-sm-none">
    <v-form>
      <v-card elevation="0">
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center mt-4">
            <h2 class="--h1">
              Para prosseguir, você precisa acessar a sua conta.
            </h2>
            <br />
            <p class="--ptext">Acesse ou crie uma conta abaixo</p>
          </div>
          <template>
            <v-card class="d-flex justify-center" elevation="0">
              <v-card-text>
                <div class="d-flex justify-center">
                  <h3>Acessar Conta</h3>
                </div>
                <v-text-field
                  :rules="[rules.required]"
                  v-model="email"
                  class="mt-2"
                  outlined
                  background-color="white"
                  dense
                  clearable
                  label="E-mail"
                >
                </v-text-field>
                <v-text-field
                  :rules="[rules.required]"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="password"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  outlined
                  background-color="white"
                  dense
                  clearable
                  label="Senha"
                >
                </v-text-field>
                <v-btn
                  block
                  :loading="loading"
                  color="#1ab873"
                  @click="logIn()"
                >
                  <span style="color: white">Acessar</span>
                </v-btn>
                <template>
                  <div class="d-flex justify-center mt-4">
                    <a
                      ><span style="color: black"
                        ><u>Esqueci a senha</u></span
                      ></a
                    >
                  </div>
                </template>
                <v-btn
                  class="mt-6"
                  outlined
                  block
                  color="black"
                  @click="createAccount()"
                >
                  Criar nova conta
                </v-btn>
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-form>

    <v-dialog v-model="error" persistent max-width="300">
      <v-card>
        <v-img contain max-height="30" width="100%"> </v-img>
        <!-- src="https://lion-web-site.s3.amazonaws.com/public/canal-do-midia.svg" -->
        <v-card-title>Falha no login</v-card-title>
        <v-card-text>Revise os dados inseridos e tente novamente</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="error = false">
            Tentar novamente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</div>
</template>

<script>
import { mapActions } from "vuex";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";

export default {
  name: "LoginForm",

  data() {
    return {
      db: null, // define a instância do Firestore como nula
      show1: false,
      loading: false,
      email: "",
      password: "",
      error: false,
      rules: {
        required: (value) => !!value || "Obrigatório.",
      },
    };
  },

  props: {
    scrollPosition: Number,
  },

  computed: {},

  mounted() {
    this.$db = getFirestore();
  },

  methods: {
    createAccount() {
      this.$router.push("/create-account");
    },

    ...mapActions("googleAds", ["setUid"]),

    async logIn() {
      this.loading = true;
      const password = this.password;
      const email = this.email;
      const auth = getAuth();
      console.log(auth);
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {

          this.user = userCredential.user;
          this.$data.currentUser = userCredential.user;
          this.verifyUser();
          console.log(userCredential.user);
          const uid = userCredential.user.uid;
          this.setUid(uid);
          
          // this.loading = false
          // this.dialog = false
          // this.$router.push({path:"/"});
        })
        .catch((error) => {
          this.processing = false;
          this.error = true;
          console.error("Erro ao efetuar login: ", error);
        });
    },

    async verifyUser() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

      try {
        // Cria referência ao documento de perfil do usuário no Firestore
        const profileRef = doc(this.$db, `profile/${user.uid}`);
        const profileDoc = await getDoc(profileRef);
        const profileData = profileDoc.data();
        const token = localStorage.getItem('@aioads:token')
        if(!token && profileData && profileData.accessToken){
          localStorage.setItem('@aioads:token', profileData.accessToken)
        }

       // if (!profileData) {
          this.loading = false;
          this.$router.push("/");
        // } else if (!profileData.companyname) {
        //   this.loading = false;
        //   this.$router.push("/where");
        // } else if (profileData.payed === false) {
        //   this.loading = false;
        //   this.$router.push("/payment");
        // } else {
        //   this.loading = false;
        //   this.$router.push("/resume");
        // }
      } catch (error) {
        console.error("Erro ao verificar usuário: ", error);
        this.loading = false;
        this.error = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.--p {
  font-size: 12px;
  color: #221b15;
}
.--ptext {
  padding-top: 1rem;
  font-size: 18px;
  color: #333333;
}
.--h1 {
  font-weight: 700;
  line-height: 2rem;
  font-size: 30px;
  padding-top: 1rem;
  color: #333333;
}
.--h2 {
  font-size: 20px;
  color: #000000;
}
.--title {
  font-size: 20px;
  font-weight: 700;
  color: #221b15;
}
.--subtitle {
  font-size: 20px;
  font-weight: 600;
  color: #221b15;
}
</style>
