<template>
  <v-card>
    <v-card-text class="pa-6">
      <h2 class="--title pb-4">Meus dados</h2>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <h3 class="--subtitle pb-4">Dados básicos</h3>
              <div class="d-flex justify-space-around">
                <v-btn outlined class="mx-4"
                  ><v-icon class="pr-1">mdi-mail</v-icon>Alterar e-mail</v-btn
                >
                <v-btn outlined class="mx-4"
                  ><v-icon class="pr-1">mdi-lock</v-icon>Alterar senha</v-btn
                >
              </div>
              <div class="d-flex justify-center pt-6">
                <v-avatar size="71"
                  ><v-img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                  ></v-img
                ></v-avatar>
              </div>
              <div class="d-flex justify-center pt-6">
                <p class="--anchor">Trocar imagem</p>
              </div>
              <div>
                <v-text-field
                  v-model="fullname"
                  background-color="white"
                  label="Nome"
                  outlined
                  clearable
                  required
                  filled
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  background-color="gray"
                  label="E-mail"
                  outlined
                  disabled
                  filled
                  required
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="phonenumber"
                  background-color="white"
                  label="Telefone"
                  outlined
                  filled
                  required
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="document"
                  background-color="gray"
                  label="CPF/CNPJ"
                  outlined
                  disabled
                  filled
                  required
                  dense
                ></v-text-field>
              </div>
              <div class="d-flex justify-center">
                <v-btn :loading="loading" color="#1ab873" block>
                <span style="color: white">Salvar</span>
              </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "profiledata-component",
  data() {
    return {
      loading: false,
      fullname: "Dany Chvaicer",
      email: "dany@themediatrade.com",
      phonenumber: "+55 11 983260317",
      document: "27.581.093/0001-41"
    };
  },
};
</script>

<style scoped lang="scss">
.--title {
  font-weight: 700;
  color: #000000;
}
.--subtitle {
  font-weight: 700;
  color: #000000;
}
.--p {
  padding: 2rem;
}
.--anchor {
  color: #509e9e;
}
@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
