import axios from "axios";

//const baseURL = "https://themediatrade.com/api/v1";
const baseURL =  window.location.hostname == 'aionlineads.canaldoanuncio.com'? 'https://canaldoanuncio.com/api/v1'  : "http://canaldoanuncio.test/api/v1";
//const baseURL = "https://canaldoanuncio.com/api/v1";
//const baseURL = "https://bdac-45-165-120-112.ngrok-free.app/api/v1";
export {baseURL};

axios.defaults.baseURL = baseURL;
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('@aioads:token')
  //  console.log('axios config', config)
    if(token){
        config.headers['grantToken'] = token
    }
    return config;
});


export default axios;