<template>
  <v-container class="pr-16 pl-16">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="d-flex flex-column justify-center text-center"
      >
        <h1 v-html="$t('resume.title')" class="--h1"></h1>
        <p v-html="$t('resume.subtitle')" class="--p"></p>
      </v-col>
    </v-row>
    <v-row class="pb-16">
      <v-col cols="4">
        <v-card class="mb-3 pa-4" outlined>
          <v-card-text>
            <p class="--adstitle">Minha assinatura</p>
            <div class="d-flex justify-space-between pb-3">
              <div><b>Valor total</b></div>
              <div>
                <b>{{ $n(googleAds.finalPrice, "currency") }} /mês</b>
              </div>
            </div>
            <v-divider class="pb-3"></v-divider>
            <div class="d-flex justify-space-between">
              <div>Último pagamento</div>
              <!-- <div>{{ timeStamp }}</div> -->
            </div>
            <div class="d-flex justify-space-between">
              <div>Próximo pagamento</div>
              <!-- <div>{{ timeStamp }}</div> -->
            </div>
            <div class="d-flex justify-end pt-3">
              <v-btn outlined small>Ver histórico de pagamento</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-4" outlined>
          <v-card-text>
            <p class="--adstitle">Minha campanha</p>
            <div class="pb-3">
              <div>
                <p>Por favor, confira e aprove os detalhes</p>
                <p>
                  Ao longo da campanha vamos sempre buscar novas oportunidades e
                  modificações para otimizar suas campanhas, sempre trabalhando
                  para alcançar os seus objetivos.
                </p>
              </div>
              <div class="d-flex justify-end pb-6">
                <v-btn
                  dark
                  :loading="loading"
                  small
                  color="#1ab873"
                  @click="sendData()"
                  :disabled="status === 'Ativa'"
                  >Aprovar tudo e iniciar a campanha</v-btn
                >
              </div>
            </div>
            <v-divider class="pt-10"></v-divider>
            <div class="d-flex">
              <p class="--adstitle">Status da campanha</p>
            </div>
            <div class="pb-10 d-flex">
              <div>
                <v-chip>{{ order && order.status ? parseStatusToText(order.status) :'indefinido' }}</v-chip>
              </div>
              <div class="pl-3" style="padding-top: 5px">
                Clique no botão acima para ativar sua campanha
              </div>
            </div>
            <v-divider class="pt-10"></v-divider>
            <div class="d-flex">
              <p class="--adstitle">Anúncios da campanha</p>
            </div>
            <div class="pb-10" v-if="order">
              <v-col
                cols="12"
                md="12"
                v-for="(item, index) in order.advertises"
                :key="item.id"
              >
                <!-- order.advertises[index] = $event" -->
                <AdvertiseResume
                  :advertise="item"
                  @save-advertise="updateAdvertise(index, $event)"
                />
              </v-col>
              <!-- {{ advertises }} -->
            </div>
            <!-- <v-divider class="pt-10"></v-divider>
            <div class="d-flex justify-space-between">
              <p class="--adstitle">Palavras chave</p>
              <div>
                <v-btn outlined small @click="keywordsDialog = true"
                  >Editar</v-btn
                >
              </div>
            </div>
            <div class="pb-10">
              <v-chip
                class="mr-2 mt-2"
                v-for="(keywords, index) in keywords"
                :key="index"
                >{{ keywords }}</v-chip
              >
            </div> -->
            <v-divider class="pt-10"></v-divider>
            <!-- <div class="d-flex justify-space-between">
              <p class="--adstitle">Localidades</p>
              <div>
                <v-btn outlined small @click="cityDialog = true">Editar</v-btn>
              </div>
            </div>
            <div class="pb-10">
              <v-chip>{{ city }}</v-chip>
            </div>
            <v-divider class="pt-10"></v-divider> -->
            <div class="d-flex justify-space-between">
              <p class="--adstitle">Página</p>
              <div>
                <v-btn outlined small @click="websiteDialog = true"
                  >Editar</v-btn
                >
              </div>
            </div>
            <p>
              Link da página que você deseja receber mais visitantes através
              desta campanha
            </p>
            <p>
              <u
                ><b>{{ website }}</b></u
              >
            </p>
            <!-- <v-divider class="mt-5 pb-3"></v-divider>
            <div class="d-flex justify-space-between">
              <p class="--adstitle">Telefone que receberá as ligações</p>
              <div>
                <v-btn outlined small @click="cityDialog = true">Editar</v-btn>
              </div>
            </div>
            <div class="pb-10">
              <p>{{ companyphone }}</p>
            </div> -->
            <v-divider class="pt-10"></v-divider>
            <!-- <div class="d-flex justify-space-between">
              <div>Veiculação</div>
              <div><v-btn outlined small>Editar</v-btn></div>
            </div>
            <p>Períodos em que você deseja que sua campanha seja veiculada</p>
            <v-data-table
              :headers="headers"
              :items="days"
              class="elevation-1"
            ></v-data-table>
            <div class="d-flex justify-center pt-7">
              <v-btn :loading="loading" small dark color="#1ab873" @click="sendData()">Aprovar tudo e iniciar campanha
              </v-btn>
            </div> -->
            <div class="d-flex justify-center pt-7">
              <p>
                Precisa da ajuda de um especialista?
                <u><b>Clique no botão abaixo</b></u>
              </p>
            </div>
            <div class="d-flex justify-center pt-1">
              <v-btn
                dark
                block
                color="#1ab873"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5511963929442"
              >
                Entrar em contato →
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card class="pt-4">
        <v-img
          src="../assets/canal-do-anuncio.svg"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>Parabéns</v-card-title>
        <v-card-text
          ><b>Sua campanha foi ativa com sucesso.</b><br /><br />Entraremos em
          contato em breve para informar o status ou caso haja alguma
          dúvida.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">
            Volte para a tela de resumo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="keywordsDialog" persistent max-width="600">
      <v-card class="pt-4">
        <v-img
          src="../assets/canal-do-anuncio.svg"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>Palavras Chave</v-card-title>
        <v-card-text
          >Insira ou remova as palavras chaves conforme sua
          necessidade:</v-card-text
        >
        <v-text-field
          class="pr-6 pl-6"
          :value="keywords"
          required
          outlined
          background-color="white"
          dense
          clearable
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveKeywords()"> Fechar </v-btn>
          <v-btn color="primary" text @click="saveKeywords()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cityDialog" persistent max-width="600">
      <v-card class="pt-4">
        <v-img
          src="../assets/canal-do-anuncio.svg"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>Cidades</v-card-title>
        <v-card-text
          >Insira ou remova as regiões conforme sua necessidade:</v-card-text
        >
        <v-text-field
          class="pr-6 pl-6"
          :value="city"
          required
          outlined
          background-color="white"
          dense
          clearable
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveKeywords()"> Fechar </v-btn>
          <v-btn color="primary" text @click="saveKeywords()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="websiteDialog" persistent max-width="600">
      <v-card class="pt-4">
        <v-img
          src="../assets/canal-do-anuncio.svg"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>Palavras Chave</v-card-title>
        <v-card-text
          >Edite a página que receberá o tráfego conforme sua
          necessidade:</v-card-text
        >
        <v-text-field
          class="pr-6 pl-6"
          :value="website"
          required
          outlined
          background-color="white"
          dense
          clearable
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveKeywords()"> Fechar </v-btn>
          <v-btn color="primary" text @click="saveKeywords()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import AdvertiseResume from "../components/ads/AdvertiseResume.vue";
import { mapGetters } from 'vuex';

export default {
  name: "resume-page",
  components: {
    AdvertiseResume,
  },

  data() {
    return {
      fireAdv: [],
      order: null,
      orders: null,
      ordersStatus:{
        pending:"Pendente",
        paid:"Pago aguardando início",
        canceled:"Cancelada",
        refused:"Recusada",
      },
      headers: [
        {
          text: "Dias",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Início", value: "startingtime" },
        { text: "Término", value: "finishingtime" },
      ],
      days: [
        {
          name: "Segunda a Sexta-Feira",
          startingtime: "08h00",
          finishingtime: "20h00",
        },
        {
          name: "Finais de semana",
          startingtime: "10h00",
          finishingtime: "18h00",
        },
      ],
      loading: false,
      website: "",
      companyphone: "",
      advertises: [],
      keywords: [],
      city: "",
      status: "",
      dialog: false,
      keywordsDialog: false,
      cityDialog: false,
      websiteDialog: false,
    };
  },
  props: {
    scrollPosition: Number,
  },

  computed: {
    ...mapGetters('googleAds',['googleAds'])
  },

  created() {
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(this.$db, `profile/${user.uid}`);
   // console.log(docRef);

    getDoc(docRef)
      .then((doc) => {
     //   console.log(doc.data());
        if (doc.exists()) {
          // Extrai o valor do dado do documento
          this.name = doc.data().name;
          this.website = doc.data().website;
          this.companyphone = doc.data().companyphone;
        // this.finalPrice = doc.data().finalPrice;
          this.keywords = doc.data().keywords;
          this.advertises = doc.data().advertises;
          this.status = doc.data().status;
         // this.finalPrice = doc.data().finalPrice;
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o documento: ", error);
      });
  },

  mounted() {
    this.getOrders();
  },
  destroyed() {},

  methods: {
    getOrders() {
      this.$db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      this.getOrdersById(user.uid);
    },

    parseStatusToText(status){
      return this.ordersStatus[status]
    },
    updateAdvertise(index, event) {
      console.log("Pré tratamento", event);
      if (this.order && this.order.id && event && typeof event === "object") {
        this.order.advertises[index] = event;
        console.log("Console da order", this.order);
        updateDoc(doc(this.$db, `orders/${this.order.id}`), {
          advertises: this.order.advertises,
        });
        this.getOrders()
      } else {
        console.error("Erro no objeto");
      }
    },

    async sendData() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

      const status = (this.status = "Ativa");

      try {
        // Cria referência ao documento de endereço do usuário no Firestore
        const statusRef = doc(this.$db, `profile/${user.uid}`);

        // Salva os dados de endereço no documento de endereço
        await updateDoc(statusRef, {
          status,
        });

        // Redireciona para a próxima página
        this.dialog = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    closeDialog() {
      this.dialog = false;
      window.location.reload();
    },

    saveKeywords() {
      this.keywordsDialog = false;
      this.cityDialog = false;
      this.websiteDialog = false;
    },

    async getOrdersById(uid) {
      const q = query(collection(this.$db, "orders"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);

      const orders = [];
      querySnapshot.forEach((doc) => {
        orders.push({ id: doc.id, ...doc.data() });
        this.order = { id: doc.id, ...doc.data() };
      });

      this.orders = orders;
      let advertises = orders.map((order) => order.advertises);
      this.fireAdv = advertises.flat();
     // console.log("advertises", this.fireAdv);
     // console.log("orders", orders);
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  padding-top: 2rem;
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 141px;
  padding-bottom: 141px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
