<template>
  <div>
    <v-container>
      <div>
        <LoginForm />
      </div>
    </v-container>
  </div>
</template>

<script>
import LoginForm from "../components/forms/LoginForm.vue";
export default {
  components: {
    LoginForm,
  },
  name: "login-page",
  data() {
    return {};
  },
  methods: {},
};
</script>
