<template>
  <div>
    <v-card-text class="pa-6">
      <v-row class="d-flex">
        <v-col class="d-flex flex-column" cols="9"
          ><h2 class="--title pb-2">Minhas campanhas</h2>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar..."
          ></v-text-field
        ></v-col>
        <v-col class="d-flex align-center" cols="3">
          <v-btn color="#333333" outlined class="mt-6">
            Criar nova campanha</v-btn
          ></v-col
        >
      </v-row>
      <v-row>
        <template>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page="5"
            class="elevation-1 d-flex flex-column mr-auto"
          ></v-data-table>
        </template>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "campaigns-component",
  data() {
    return {
      fullname: "Dany Chvaicer",
      email: "dany@themediatrade.com",
      phonenumber: "+55 11 983260317",
      document: "27.581.093/0001-41",
      headers: [
          {
            text: 'Campanha',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Código', value: 'calories' },
          { text: 'Data de início', value: 'fat' },
          { text: 'Investimento', value: 'carbs' },
          { text: 'Próximo pagamento', value: 'protein' },
          { text: 'Status', value: 'iron' },
        ],
        desserts: [
          {
            name: 'Landing Page CDM',
            calories: '#112479',
            fat: '06/03/2023',
            carbs: 'R$ 2000',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'Landing Page CDA',
            calories: '#145479',
            fat: '06/03/2023',
            carbs: 'R$ 3500',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'The Media Trade',
            calories: '#242479',
            fat: '06/03/2023',
            carbs: 'R$ 2500',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'FX Company',
            calories: '#512479',
            fat: '06/03/2023',
            carbs: 'R$ 4000',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'FX Company',
            calories: '#712479',
            fat: '06/03/2023',
            carbs: 'R$ 4000',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'FX Company',
            calories: '#112479',
            fat: '06/03/2023',
            carbs: 'R$ 4000',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'FX Company',
            calories: '#112479',
            fat: '06/03/2023',
            carbs: 'R$ 4000',
            protein: '06/04/2023',
            iron: 'Ativo',
          },
          {
            name: 'Honeycomb',
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: 45,
          },
          {
            name: 'Donut',
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: 22,
          },
          {
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: 6,
          },
        ],
    };
  },
};
</script>

<style scoped lang="scss">
.--title {
  font-weight: 700;
  color: #000000;
}
.--subtitle {
  font-weight: 700;
  color: #000000;
}
.--p {
  padding: 2rem;
}
.--anchor {
  color: #509e9e;
}
@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
