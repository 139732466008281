<template>
  <v-card color="#f4fff0"
    ><v-card-text>
      <div class="d-flex justify-space-around align-center">
        <h3>Anúncio</h3>

        
        <div class="d-flex align-center">
          <h3 class="--subtitle pr-2">Desativar anúncio</h3>
          <v-switch v-model="anuncio1"></v-switch>
        </div>
      </div>
      <template>
        <v-card>
          <v-card-text>
            <div>
              <div class="--title" v-html="advertise.title"></div>
              <br />
              <div class="--description" v-html="advertise.description"></div>
            </div>
            <!-- <div>
              <v-card-text class="--description">{{ advertise.keywords }}</v-card-text>
            </div> -->
            <div class="--bottom"></div>
            <div>
              <v-btn color="black" dark block @click="editingModal = true"
                >Editar anúncio
              </v-btn>
            </div>

            <v-dialog v-model="editingModal" persistent max-width="600">
              <v-card class="pa-4">
                <v-card-title>Edite seu anúncio abaixo:</v-card-title>
                <v-text-field
                  v-model="snapAdvertise.title"
                  outlined
                  counter="30"
                  label="Título"
                ></v-text-field>
                <v-textarea
                  v-model="snapAdvertise.description"
                  outlined
                  counter="90"
                  label="Descrição do anúncio"
                ></v-textarea>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#1ab873"
                    dark
                    :loading="processing"
                    @click="updateAd()"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </template></v-card-text
    ></v-card
  >
</template>

<script>
import { getFirestore,
  // updateDoc,
    doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default {
  name: "advertise-resume",
  data() {
    return {
      processing: false,
      editingModal: false,
      anuncio1: true,
      editing: false,
      snapAdvertise: {
        title: "",
        description: "",
      },
    };
  },

  props: {
    advertise: Object,
    scrollPosition: Number,
  },

  computed: {},

  created() {
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(this.$db, `orders/${user.uid}`);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          // Extrai o valor do dado do documento
          const companyname = doc.data().companyname;
          const description = doc.data().website;

          // Atualiza a propriedade do componente com o valor do dado
          this.companyname = companyname;
          this.description = description;
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o documento: ", error);
      });
  },
  mounted() {
    this.snapAdvertise = JSON.parse(JSON.stringify(this.advertise));
  },
  destroyed() {},

  methods: {
    async updateAd() {
      // if (!this.$db) {
      //   console.error("Objeto this.$db não definido.");
      //   return;
      // }
      // this.processing = true;
      // const auth = getAuth();
      // const user = auth.currentUser;
      // // Verifica se o objeto user está definido e tem a propriedade uid
      // if (!user || !user.uid) {
      //   console.error("Usuário não autenticado.");
      //   return (this.error = true);
      // }

      // const advertise = this.snapAdvertise;

      // // console.log('Anúncio', advertise);
      // const userRef = doc(this.$db, `order/${user.uid}`);
      // // console.log('Docref', docRef);
      // await updateDoc(userRef, advertise)
      //   .then(() => {
      //     console.log("Documento atualizado com sucesso!");
      //     this.processing = false;
      //     this.editingModal = false;
      //   })
      //   .catch((error) => {
      //     console.error("Erro ao atualizar o documento: ", error);
      //     this.processing = false;
      //   });
      this.$emit("save-advertise", this.snapAdvertise);
      this.editingModal = false;


    },
    
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--title {
  padding-top: 1rem;
  font-size: 21px;
  color: #333333;
  font-weight: 600;
}
.--description {
  padding-top: 1rem;
  font-size: 18px;
  color: #333333;
}
.--bottom {
  padding-bottom: 4rem;
}
.--form {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
