<template>
  <div class="--fundo">
    <v-container>
      <v-row>

        <v-col cols="7" md="7" class="d-flex flex-column display-sm-and-up">
          <h1
            v-html="$t('firstsection.title')"
            class="--h1 hidden-xs-only"
          ></h1>
          <p
            v-html="$t('firstsection.paragraph')"
            class="--p hidden-xs-only"
          ></p>
          <v-form class="hidden-xs-only">
            <v-card elevation="0">
              <v-row
                class="d-flex flex-md-row flex-column"
                style="background-color: #daf7f7"
              >
                <v-col cols="12">
                  <v-select
                    v-model="objective"
                    clearable
                    :rules="[rules.required]"
                    class="pr-1"
                    background-color="white"
                    :items="items"
                    @change="setObjective(objective)"
                    label="Objetivo da campanha"
                    placeholder="Selecione"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="6" class="mt-n6">
                  <v-text-field
                    :rules="[
                      rules.required,
                      (value) =>
                        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value) ||
                        'URL inválida',
                    ]"
                    v-model="website"
                    clearable
                    class="pr-1"
                    background-color="white"
                    placeholder="Seu website"
                    outlined
                    filled
                    dense
                    @keyup="setWebsite(website)"
                  ></v-text-field>
                  <v-text-field
                    :rules="[
                      rules.required,
                      (value) =>
                        /^((https?:\/\/)?(www\.)?)?instagram\.com\/.*$/i.test(
                          value
                        ) || 'URL do Instagram inválida',
                    ]"
                    v-model="instagram"
                    clearable
                    class="pr-1"
                    background-color="white"
                    placeholder="Seu instagram"
                    outlined
                    filled
                    dense
                    @keyup="setInstagram(instagram)"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="mt-n6">
                  <v-text-field
                    :rules="[
                      rules.required,
                      (value) =>
                        /^((https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*)$/i.test(
                          value
                        ) || 'URL do Facebook inválida',
                    ]"
                    v-model="facebook"
                    clearable
                    class="pr-1"
                    background-color="white"
                    placeholder="Seu facebook"
                    outlined
                    dense
                    @keyup="setFacebook(facebook)"
                  ></v-text-field>
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="companyname"
                    clearable
                    class="pr-1"
                    background-color="white"
                    placeholder="Nome de sua empresa"
                    outlined
                    dense
                    @keyup="setCompanyName(companyname)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="d-flex flex-md-row flex-column align-end"
                style="background-color: #daf7f7"
              >
                <v-col cols="6" class="mt-n6"> </v-col>
                <v-col cols="6" class="mt-n6">
                  <v-btn
                    dark
                    color="#14A3A3"
                    class="mr-4"
                    block
                    elevation="0"
                    @click="dialog = true"
                  >
                    {{ $t("header.button") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-col>

        <v-col cols="12" md="12" class="d-flex flex-column d-sm-none">
          <h1 v-html="$t('firstsection.title')" class="--h1"></h1>
          <p v-html="$t('firstsection.paragraph')" class="--p"></p>
          <v-form class="d-sm-none">
            <v-card color="#ffffff">
              <v-card-text>
                <v-row>
                  <v-col class="d-flex flex-md-row flex-column">
                    <v-text-field
                      :rules="[
                        rules.required,
                        (value) =>
                          /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value) ||
                          'URL inválida',
                      ]"
                      v-model="website"
                      clearable
                      background-color="white"
                      placeholder="Seu website"
                      outlined
                      filled
                      dense
                      @keyup="setWebsite(website)"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                      :rules="[
                        rules.required,
                        (value) =>
                          /^((https?:\/\/)?(www\.)?)?instagram\.com\/.*$/i.test(
                            value
                          ) || 'URL do Instagram inválida',
                      ]"
                      v-model="instagram"
                      clearable
                      background-color="white"
                      placeholder="Seu instagram"
                      outlined
                      filled
                      dense
                      @keyup="setInstagram(instagram)"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                      :rules="[
                        rules.required,
                        (value) =>
                          /^((https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*)$/i.test(
                            value
                          ) || 'URL do Facebook inválida',
                      ]"
                      v-model="facebook"
                      clearable
                      background-color="white"
                      placeholder="Seu facebook"
                      outlined
                      dense
                      @keyup="setFacebook(facebook)"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                      :rules="[rules.required]"
                      v-model="companyname"
                      clearable
                      background-color="white"
                      placeholder="Nome de sua empresa"
                      outlined
                      dense
                      @keyup="setCompanyName(companyname)"
                    ></v-text-field>
                    <v-select
                      v-model="objective"
                      clearable
                      :rules="[rules.required]"
                      background-color="white"
                      :items="items"
                      @change="setObjective(objective)"
                      label="Objetivo da campanha"
                      placeholder="Selecione"
                      dense
                      outlined
                    ></v-select>
                    <v-btn
                      dark
                      color="#14A3A3"
                      class="mr-4"
                      block
                      elevation="0"
                      @click="dialog = true"
                    >
                      {{ $t("header.button") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
        
      </v-row>
      <v-row class="--bottom"></v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="1000">
      <DescriptionForm @popupform:close="descriptiondialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import DescriptionForm from "../forms/DescriptionForm.vue";
import { mapActions } from "vuex";
import { required, url } from "vuelidate/lib/validators";

export default {
  components: {
    DescriptionForm,
  },
  data() {
    return {
      companyname: "",
      website: "https://www.",
      facebook: "https://www.facebook.com/",
      instagram: "https://www.instagram.com/",
      linkedin: "",
      objective: "",
      items: [
        "Mais visitas ao seu site",
        "Mais vendas no seu site",
        "Mais vistas/vendas nas suas redes sociais",
        "Mais visitas a sua loja física",
        "Mostrar a sua marca (awareness)",
        "Mais ligações telefônicas",
        "Mais visitas para seu canal do Youtube",
      ],
      loading: false,
      dialog: false,
      resposta: "",
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 6 || "Min 6 caractéres",
        min14: (v) => v.length >= 18 || "Min 14 números",
        min11: (v) => v.length >= 14 || "Min 11 números",
        emailMatch: () => `O email e a senha não são compatíveis`,
        counter: (value) => value.length <= 20 || "Max 20 caractéres",
        confirmRule(password) {
          return (v) => password == v || "As senhas não estão iguais";
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail inválido";
        },
      },
      validations: {
        website: {
          required,
          url,
        },
      },
    };
  },
  props: {
    scrollPosition: Number,
  },
  created() {},
  mounted() {},
  destroyed() {},

  methods: {
    ...mapActions("googleAds", [
      "setCompanyName",
      "setDescription",
      "setAdvertises",
      "setObjective",
      "setWebsite",
      "setFacebook",
      "setInstagram",
    ]),
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #094949;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #5e5e5e;
}
.--bottom {
  padding-bottom: 5rem;
}
.--form {
  background-color: #daf7f7;
}
.--fundo {
  background-image: url(../../assets/cover-first-section.png);
  background-size: 100%;
}
.--fundomobile {
  background-image: url(../../assets/cover-first-section-mobile.png);
  background-size: 100%;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
