<template>
  <div>
  <v-card>
    <v-card-text class="pa-6">
      <v-card class="mt-10">
        <v-card-text>
          <div class="d-flex justify-space-around align-center">
            <div><h3 class="--subtitle">Endereços</h3></div>
            <div>
              <v-btn dark :loading="loading" color="#1ab873" block @click="dialog = true">
                Novo endereço
              </v-btn>
            </div>
          </div>
          <div class="d-flex justify-center mt-10"><h4>Principal</h4></div>
          <div>
            <v-card class="mt-2" color="#e0ffff">
              <v-card-text>
                <v-row class="d-flex justify-space-around align-center">
                  <v-col cols="11">
                    <p>
                      Travessa Antonio Laval, 2 - São Paulo - SP -
                      02258-040
                    </p></v-col
                  >
                  <v-col cols="1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
          <div class="d-flex justify-center mt-5"><h4>Outros</h4></div>
          <div>
            <v-card class="mt-2">
              <v-card-text>
                <v-row class="d-flex justify-space-around align-center">
                  <v-col cols="11">
                    <p>
                      Rua Vicente Rodrigues da Silva, 788 - Osasco - SP -
                      06230-096
                    </p></v-col
                  >
                  <v-col cols="1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-text>
                <v-row class="d-flex justify-space-around align-center">
                  <v-col cols="11">
                    <p>
                      Rua Vicente Rodrigues da Silva, 788 - Osasco - SP -
                      06230-096
                    </p></v-col
                  >
                  <v-col cols="1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-3">
              <v-card-text>
                <v-row class="d-flex justify-space-around align-center">
                  <v-col cols="11">
                    <p>
                      Rua Vicente Rodrigues da Silva, 788 - Osasco - SP -
                      06230-096
                    </p></v-col
                  >
                  <v-col cols="1">
                    <v-icon>mdi-pencil</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
  <v-dialog v-model="dialog" max-width="1000">
    <NewAddressVue></NewAddressVue>
  </v-dialog>
</div>
</template>

<script>
import NewAddressVue from './NewAddress.vue';

export default {
  name: "address-component",
  components: {
    NewAddressVue
  },
  data() {
    return {
      loading: false,
      dialog: false,
      fullname: "Dany Chvaicer",
      email: "dany@themediatrade.com",
      phonenumber: "+55 11 983260317",
      document: "27.581.093/0001-41",
    };
  },
};
</script>

<style scoped lang="scss">
.--title {
  font-weight: 700;
  color: #000000;
}
.--subtitle {
  font-weight: 700;
  color: #000000;
}
.--p {
  padding: 2rem;
}
.--anchor {
  color: #509e9e;
}
@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
