<template>
    <v-container class="pr-16 pl-16">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-column justify-center text-center"
        >
          <h1 v-html="$t('confirmation.title')" class="--h1"></h1>
          <p v-html="$t('confirmation.subtitle')" class="--p"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col> <p class="--adstitle">3. Resumo do pedido</p></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-text>
                      <p class="--adstitle">Pacote 1</p>
                      <v-divider class="pa-2"></v-divider>
                      <v-icon color="black"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      Acompanhamento mensal personalizado<v-divider
                        class="pa-2"
                      ></v-divider
                      ><v-icon color="black"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      Relatórios de performance<v-divider class="pa-2"></v-divider
                      ><v-icon color="black"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      Visualização online da campanha na plataforma do
                      Google<v-divider class="pa-2"></v-divider
                      ><v-icon color="black"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                      Reunião mensal de acompanhamento de performance e briefing
                      para novas campanhas
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <div class="d-flex justify-space-between">
                    <div><p>Anúncios</p></div>
                    <div><p>4</p></div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div><p>Taxa de gestão</p></div>
                    <div><p>20%</p></div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div><p>Campanha</p></div>
                    <div><p>R$2000</p></div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div><p class="--adstitle">Total pago</p></div>
                    <div><p class="--adstitle">R$2400</p></div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center" cols="6"> </v-col>
        <v-col class="d-flex justify-center" cols="6">
          <v-btn dark block :loading="loading" color="#1ab873" @click="sendData()">
            Aprovar campanha
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pb-16"></v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "confirmation-page",
  
    data() {
      return {
        loading: false,
        price: "",
        finalhour: "",
        statusTitle: false,
        colorTitle: "",
        colorTitle1: "",
        colorTitle2: "",
      };
    },
    props: {
      scrollPosition: Number,
    },
    created() {},
    mounted() {},
    destroyed() {},
  
    methods: {
      sendData() {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/resume");
        }, 5000);
      },
  
      changeColor() {
        if (this.statusTitle === false) {
          this.statusTitle = true;
          return (this.colorTitle = "green lighten-5");
        }
        if (this.statusTitle === true) {
          this.statusTitle = false;
          return (this.colorTitle = "white");
        }
      },
      changeColor1() {
        if (this.statusTitle === false) {
          this.statusTitle = true;
          return (this.colorTitle1 = "green lighten-5");
        }
        if (this.statusTitle === true) {
          this.statusTitle = false;
          return (this.colorTitle1 = "white");
        }
      },
      changeColor2() {
        if (this.statusTitle === false) {
          this.statusTitle = true;
          return (this.colorTitle2 = "green lighten-5");
        }
        if (this.statusTitle === true) {
          this.statusTitle = false;
          return (this.colorTitle2 = "white");
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .--h1 {
    font-weight: 700;
    font-size: 40px;
    padding-top: 1rem;
    color: #333333;
  }
  .--p {
    padding-top: 2rem;
    font-size: 18px;
    color: #333333;
  }
  .--adstext {
    font-size: 14px;
    color: #333333;
  }
  .--adstitle {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .--subtitle {
    font-weight: 400;
  }
  .--bottom {
    padding-bottom: 4rem;
  }
  
  .--form {
    padding-top: 117px;
    padding-bottom: 118px;
  }
  
  @keyframes changewidth {
    0% {
      bottom: -2px;
    }
    50% {
      bottom: -2px;
    }
    100% {
      bottom: 28px;
    }
  }
  </style>
  