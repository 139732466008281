export function googleAds(state) {
    return state.googleAds;
  }
export function budget(state) {
    return state.googleAds.budget;
  }

  export function advertises(state) {
    return state.googleAds.advertises;
  }

  export function destination(state) {
    return state.googleAds.website;
  }