var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--fundo"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column display-sm-and-up",attrs:{"cols":"7","md":"7"}},[_c('h1',{staticClass:"--h1 hidden-xs-only",domProps:{"innerHTML":_vm._s(_vm.$t('firstsection.title'))}}),_c('p',{staticClass:"--p hidden-xs-only",domProps:{"innerHTML":_vm._s(_vm.$t('firstsection.paragraph'))}}),_c('v-form',{staticClass:"hidden-xs-only"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"d-flex flex-md-row flex-column",staticStyle:{"background-color":"#daf7f7"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"pr-1",attrs:{"clearable":"","rules":[_vm.rules.required],"background-color":"white","items":_vm.items,"label":"Objetivo da campanha","placeholder":"Selecione","dense":"","outlined":""},on:{"change":function($event){return _vm.setObjective(_vm.objective)}},model:{value:(_vm.objective),callback:function ($$v) {_vm.objective=$$v},expression:"objective"}})],1),_c('v-col',{staticClass:"mt-n6",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"pr-1",attrs:{"rules":[
                    _vm.rules.required,
                    (value) =>
                      /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value) ||
                      'URL inválida',
                  ],"clearable":"","background-color":"white","placeholder":"Seu website","outlined":"","filled":"","dense":""},on:{"keyup":function($event){return _vm.setWebsite(_vm.website)}},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('v-text-field',{staticClass:"pr-1",attrs:{"rules":[
                    _vm.rules.required,
                    (value) =>
                      /^((https?:\/\/)?(www\.)?)?instagram\.com\/.*$/i.test(
                        value
                      ) || 'URL do Instagram inválida',
                  ],"clearable":"","background-color":"white","placeholder":"Seu instagram","outlined":"","filled":"","dense":""},on:{"keyup":function($event){return _vm.setInstagram(_vm.instagram)}},model:{value:(_vm.instagram),callback:function ($$v) {_vm.instagram=$$v},expression:"instagram"}})],1),_c('v-col',{staticClass:"mt-n6",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"pr-1",attrs:{"rules":[
                    _vm.rules.required,
                    (value) =>
                      /^((https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*)$/i.test(
                        value
                      ) || 'URL do Facebook inválida',
                  ],"clearable":"","background-color":"white","placeholder":"Seu facebook","outlined":"","dense":""},on:{"keyup":function($event){return _vm.setFacebook(_vm.facebook)}},model:{value:(_vm.facebook),callback:function ($$v) {_vm.facebook=$$v},expression:"facebook"}}),_c('v-text-field',{staticClass:"pr-1",attrs:{"rules":[_vm.rules.required],"clearable":"","background-color":"white","placeholder":"Nome de sua empresa","outlined":"","dense":""},on:{"keyup":function($event){return _vm.setCompanyName(_vm.companyname)}},model:{value:(_vm.companyname),callback:function ($$v) {_vm.companyname=$$v},expression:"companyname"}})],1)],1),_c('v-row',{staticClass:"d-flex flex-md-row flex-column align-end",staticStyle:{"background-color":"#daf7f7"}},[_c('v-col',{staticClass:"mt-n6",attrs:{"cols":"6"}}),_c('v-col',{staticClass:"mt-n6",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"dark":"","color":"#14A3A3","block":"","elevation":"0"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("header.button"))+" ")])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-column d-sm-none",attrs:{"cols":"12","md":"12"}},[_c('h1',{staticClass:"--h1",domProps:{"innerHTML":_vm._s(_vm.$t('firstsection.title'))}}),_c('p',{staticClass:"--p",domProps:{"innerHTML":_vm._s(_vm.$t('firstsection.paragraph'))}}),_c('v-form',{staticClass:"d-sm-none"},[_c('v-card',{attrs:{"color":"#ffffff"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-md-row flex-column"},[_c('v-text-field',{attrs:{"rules":[
                      _vm.rules.required,
                      (value) =>
                        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value) ||
                        'URL inválida',
                    ],"clearable":"","background-color":"white","placeholder":"Seu website","outlined":"","filled":"","dense":""},on:{"keyup":function($event){return _vm.setWebsite(_vm.website)}},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"rules":[
                      _vm.rules.required,
                      (value) =>
                        /^((https?:\/\/)?(www\.)?)?instagram\.com\/.*$/i.test(
                          value
                        ) || 'URL do Instagram inválida',
                    ],"clearable":"","background-color":"white","placeholder":"Seu instagram","outlined":"","filled":"","dense":""},on:{"keyup":function($event){return _vm.setInstagram(_vm.instagram)}},model:{value:(_vm.instagram),callback:function ($$v) {_vm.instagram=$$v},expression:"instagram"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"rules":[
                      _vm.rules.required,
                      (value) =>
                        /^((https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*)$/i.test(
                          value
                        ) || 'URL do Facebook inválida',
                    ],"clearable":"","background-color":"white","placeholder":"Seu facebook","outlined":"","dense":""},on:{"keyup":function($event){return _vm.setFacebook(_vm.facebook)}},model:{value:(_vm.facebook),callback:function ($$v) {_vm.facebook=$$v},expression:"facebook"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"clearable":"","background-color":"white","placeholder":"Nome de sua empresa","outlined":"","dense":""},on:{"keyup":function($event){return _vm.setCompanyName(_vm.companyname)}},model:{value:(_vm.companyname),callback:function ($$v) {_vm.companyname=$$v},expression:"companyname"}}),_c('v-select',{attrs:{"clearable":"","rules":[_vm.rules.required],"background-color":"white","items":_vm.items,"label":"Objetivo da campanha","placeholder":"Selecione","dense":"","outlined":""},on:{"change":function($event){return _vm.setObjective(_vm.objective)}},model:{value:(_vm.objective),callback:function ($$v) {_vm.objective=$$v},expression:"objective"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"dark":"","color":"#14A3A3","block":"","elevation":"0"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t("header.button"))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"--bottom"})],1),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('DescriptionForm',{on:{"popupform:close":function($event){_vm.descriptiondialog = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }