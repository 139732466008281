<template>
  <v-container class="pr-2 pl-2">
    <div v-if="hasAdvertises">
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center text-center">
          <h1 v-html="$t('advertises.title')" class="--h1"></h1>
          <p v-html="$t('advertises.subtitle')" class="--p"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          v-for="(advertise, index) in advertises"
          :key="index"
        >
          <AdvertiseBlock :advertise="advertise" @save-ads="updateAds($event)" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <v-card style="background-color: #f4fff0" class="form-card">
            <v-card-text>
              <div class="d-flex justify-center align-center flex-column">
                <v-btn dark block color="#000000" @click="newad = true">
                  <span v-html="$t('advertises.newrequest')"></span>
                </v-btn>
                <v-btn
                  dark
                  block
                  :loading="loading"
                  color="#1ab873"
                  class="continue-button"
                  @click="sendAds()"
                >
                <span v-html="$t('advertises.continue')"></span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pt-6">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <v-card color="#f4fff0" class="description-card">
            <v-card-text>
              <h2 v-html="$t('advertises.dontlike')" class="text-ads-title">
              </h2>
              <p v-html="$t('advertises.dontlikesubtitle')" class="text-ads-text">
              </p>
              <div class="d-flex justify-center flex-column">
                <v-btn
                  outlined
                  color="#f4fff0"
                  @click="editDescription()"
                  class="pr-2"
                >
                  <span v-html="$t('advertises.editdescription')" style="color: black"
                    ></span
                  >
                </v-btn>
                <v-btn
                  outlined
                  color="#f4fff0"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=5511963929442"
                >
                  <span style="color: black" v-html="$t('advertises.contactus')"></span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pb-16"></v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center text-center">
          <h1 v-html="$t('advertises.titlenoads')" class="--h1"></h1>
          <p v-html="$t('advertises.subtitlenoads')" class="--p"></p>
        </v-col>
        <v-col class="d-flex justify-center">
          <v-btn dark dense color="#1ab873" @click="goHome()">Voltar para home</v-btn>
        </v-col>
      </v-row>
      <v-row class="pb-16"></v-row>
    </div>

    <v-dialog v-model="dialog" max-width="1000">
      <PopupForm />
    </v-dialog>

    <v-dialog v-model="newad" max-width="600">
      <v-card>
        <v-card-text>
          <v-row class="d-flex flex-column">
            <v-col>
              <br />
              <h2 class="text-ads-title">Solicitar novo anúncio</h2>
              <p>
                Escreva uma nova descrição mais precisa sobre seu produto ou
                serviço para que eu possa gerar novos resultados para você.
              </p>
              <v-textarea
                v-model="description"
                clearable
                background-color="white"
                placeholder="Digite aqui..."
                outlined
                block
                dense
                @keyup="setDescription(description)"
              ></v-textarea>
              <v-btn
                :loading="loading"
                color="#1ab873"
                dark
                @click="setNewAdvertise(profile), closeDialog()"
              >
                Solicitar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import AdvertiseBlock from "../components/ads/AdvertiseBlock.vue";
import PopupForm from "../components/forms/PopupForm.vue";
import { mapActions, mapGetters } from "vuex";
import { getFirestore, doc, updateDoc, getDoc,  collection, setDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import moment from "moment";

export default {
  name: "advertises-page",
  components: {
    PopupForm,
    AdvertiseBlock,
  },

  data() {
    return {
      userId:null,
      orderId:null,
      noAds: false,
      newad: false,
      db: null,
      description: "",
      editing: false,
      dialog: false,
      loading: false,
      titlerules: [(v) => v.length <= 30 || "Max 30 caractéres"],
      descriptionrules: [(v) => v.length <= 90 || "Max 90 caractéres"],
      profile:null
    };
  },
  props: {
    scrollPosition: Number,
  },

  // computed: {
  //   ...mapGetters("googleAds", ["advertises"]),
  // },

  computed: {
    ...mapGetters("googleAds", ["googleAds","advertises"]),
    //...mapGetters("googleAds", ["advertises, objective, description, companyname, website, facebook, instagram"]);
    hasAdvertises() {
      return this.advertises && this.advertises.length > 0
    }

  },

  created() {
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    if(user && user.uid){
      this.userId = user.uid
      this.loadProfile()
    }
  },

  mounted() {},
  destroyed() {},

  methods: {
    ...mapActions("googleAds", ["setNewAdvertise", "setDescription","updateAdvertise"]),
   async loadProfile(){
     const profileRef = doc(this.$db, `profile/${this.userId}`);
      const profileDoc = await getDoc(profileRef)
      this.profile = profileDoc.data()
    },
    createNewOrder()
    {
      return new Promise((resolve, reject)=>{ 
        try{
        const orderCollection = collection(this.$db, "orders");
        const orderId = moment().format("X");
        this.orderId = orderId
        const orderDocRef = doc(orderCollection, orderId);
        this.orderId = orderId;
        const dataOrder = {
            uid: this.userId,
            finalPrice: 0,
            status: "pending",
            budget: 0,
            zohoDealId: null,  //res.data.data.zohoDealId,
            objective: this.googleAds.objective,
            companyname: this.googleAds.companyname,
            website: this.googleAds.website,
            description: this.googleAds.description,
            createdOn: moment().format(),
            advertises: this.googleAds.advertises,
          };
        setDoc(orderDocRef, dataOrder).then(() => {
          console.log("Dados salvos com sucesso!");
        // this.processing = false;
        // this.success = true;
        });
        resolve(orderId)
      }catch(err){
        reject(err)
      }
     })
    },
    sendAds() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.$router.push("/login");
      } else {
        this.saveAdvertises();
      }
    },
    updateAds(ad){
      console.log('update ads', ad)
      this.updateAdvertise(ad)

    },

    async saveAdvertises() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      console.log("registering data");

      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

     console.log('googleAdss',this.googleAds)
      const objective = this.googleAds.objective
      const description = this.googleAds.description;
      const companyname = this.googleAds.companyname;
      const website = this.googleAds.website;
      const facebook = this.googleAds.facebook;
      const instagram = this.googleAds.instagram;
      const advertises = this.googleAds.advertises;

      try {
        // Cria referência ao documento de endereço do usuário no Firestore
        const advertisesRef = doc(this.$db, `profile/${user.uid}`);

        // Salva os dados de endereço no documento de endereço
        await updateDoc(advertisesRef, {
          objective, description, companyname, website, facebook, instagram, advertises
        }).then(()=>{
          console.log('Create new Order')
          this.createNewOrder().then((order)=>{
            //if(this.checkAddressExistis() == false){
              console.log('redirect to address')
              this.$router.push(`/where/${order}`);
           // }else{
            //  this.$router.push(`/price/${order}`);
           // }
          })
        })

        // Redireciona para a próxima página
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
   async checkAddressExistis(){
        try{
          const auth = getAuth();
          const user = auth.currentUser;
          if (!user || !user.uid) {
            return false
          }
          const addressRef = doc(this.$db, `address/${user.uid}`);
          const addressDoc =  await  getDoc(addressRef)
          const address = addressDoc.data()
          console.log('getAddress', address)
         return !!address
        }catch(err){
         console.log('Address not found', err)
        }
      },
    editDescription() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.dialog = true;
      }, 5000);
    },
    closeDialog() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.newad = false;
      }, 6000);
    },

    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  padding-top: 2rem;
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 141px;
  padding-bottom: 141px;
}
.text-h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}

.text-p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}

.text-ads-title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.text-ads-text {
  padding-top: 2rem;
  font-size: 14px;
  color: #333333;
}

.form-card {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.continue-button {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.description-card {
  padding: 2rem;
  margin-top: 2rem;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
