import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Profile from '@/views/Profile'
import Where from '@/views/Where'
import AdvertisesPage from '@/views/AdvertisesPage'
import Website from '@/views/Website'
import Hours from '@/views/Hours'
import PhoneNumber from '@/views/PhoneNumber'
import Price from '@/views/Price'
import Payment from '@/views/Payment'
import Confirmation from '@/views/Confirmation'
import MyAds from '@/views/MyAds'
import CreateAccount from '@/views/CreateAccount'
import ResumePage from '@/views/ResumePage'
import LoginPage from '@/views/Login'
import { getAuth } from "firebase/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/profile',
    name: 'profile-page',
    meta: {
      requiresAuth: true
    },
    component: Profile
  },
  {
    path: '/advertises/',
    name: 'advertises-page',
    component: AdvertisesPage
  },
  {
    path: '/where/:orderId',
    name: 'where-page',
    props:true,
    meta: {
      requiresAuth: true
    },
    component: Where
  },
  {
    path: '/website/:id',
    name: 'website-page',
    meta: {
      requiresAuth: true
    },
    component: Website
  },
  {
    path: '/hours',
    name: 'hours-page',
    meta: {
      requiresAuth: true
    },
    component: Hours
  },
  {
    path: '/phone-number/:id',
    name: 'phone-number',
    component: PhoneNumber
  },
  {
    path: '/price/:id',
    name: 'price-page',
    props:true,
    meta: {
      requiresAuth: true
    },
    component: Price
  },
  {
    path: '/payment/:id',
    name: 'payment-page',
        meta: {
      requiresAuth: true
    },
    props: true,
    component: Payment
  },
  {
    path: '/myads',
    name: 'myads-page',
    meta: {
      requiresAuth: true
    },
    component: MyAds
  },
  {
    path: '/confirmation',
    name: 'confirmation-page',
    meta: {
      requiresAuth: true
    },
    component: Confirmation
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: CreateAccount
  },
  {
    path: '/resume',
    name: 'resume-page',
    meta: {
      requiresAuth: true
    },
    component: ResumePage
  },
  {
    path: '/login',
    name: 'login-page',
    component: LoginPage
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) next(`/login`)
  else next()
});

export default router
