import mutations from './mutations'
import state from './state'
import * as getters from './getters'
import * as actions from './actions'

export default{
    actions,
    mutations,
    state,
    getters,
    namespaced:true,
}