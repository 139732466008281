// import moment from "moment";

export default {
  googleAds: {
    objective: "",
    website: "",
    facebook: "",
    companyname: "",
    instagram: "",
    description: "",
    advertises: [],
    uid: null,
    address: {
      zipcode: "",
      provincy: "",
      street: "",
      city: "",
      neighborhood: "",
      number: "",
      complement: "",
    },
    advertiseDates: [
      {
        days: "",
        starthour: "",
        finalhour: ""
      }
    ],
    destination: "",
    budget: 0,
    techFee: 0.2,
    techFeeDisplay: '20%',
    techFeeValue: 0,
    billingName: "",
    payment: null,
    adsQuantity: 0,
    finalPrice: "",
    createdOn: null,
    updatedOn: null
  }
}