<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex flex-column justify-center text-center">
        <h1 v-html="$t('where.title')" class="--h1"></h1>
        <p v-html="$t('where.subtitle')" class="--p"></p>
      </v-col>
    </v-row>

    <!-- Desktop -->

    <v-form
      style="margin-left: 15rem; margin-right: 15rem"
      class="d-none d-sm-flex flex-column"
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="12">
          <v-text-field
            dense
            v-model="address.zipcode"
            label="Insira seu CEP"
            outlined
            @blur="confirmCep"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-n6">
        <v-col cols="6" md="6">
          <v-text-field
            v-model="address.street"
            label="Rua"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.neighborhood"
            label="Bairro"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.state"
            label="Estado"
            disabled
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="6">
          <v-text-field
            v-model="address.city"
            label="Cidade"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.street_number"
            label="Número"
            outlined
            dense
            @keyup="setNumber(address.street_number), validateAddress()"
          ></v-text-field>
          <v-text-field
            v-model="address.complement"
            label="Complemento"
            @keyup="setComplement(address.complement)"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mb-16">
        <v-col cols="12" md="12">
          <v-btn
            :disabled="!addressValid"
            :loading="loading"
            color="#1ab873"
            block
            @click="sendData()"
          >
            <span style="color: white">Continuar</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Mobile -->

    <v-form class="d-sm-none">
      <v-row class="d-flex flex-column justify-center">
        <v-col cols="12" md="12">
          <v-text-field
            dense
            v-model="address.zipcode"
            label="Insira seu CEP"
            outlined
            @blur="confirmCep"
          ></v-text-field>
          <v-text-field
            v-model="address.street"
            label="Rua"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.neighborhood"
            label="Bairro"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.state"
            label="Estado"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.city"
            label="Cidade"
            disabled
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="address.street_number"
            label="Número"
            outlined
            dense
            @keyup="setNumber(address.street_number), validateAddress()"
          ></v-text-field>
          <v-text-field
            v-model="address.complement"
            label="Complemento"
            @keyup="setComplement(address.complement)"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mb-16">
        <v-col cols="12" md="12">
          <v-btn
            dark
            :disabled="!addressValid"
            :loading="loading"
            color="#1ab873"
            block
            @click="sendData()"
          >
            <span style="color: white">Continuar</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="error" persistent max-width="300">
      <v-card class="pt-4">
        <v-img
          src="../assets/canal-do-anuncio.svg"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>CEP não existente</v-card-title>
        <v-card-text
          >Digite novamente o CEP para cadastrar o endereço</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="error = false">
            Tentar novamente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="pb-10"></v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import cep from "cep-promise";
import { getFirestore,doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default {
  name: "where-page",

  data() {
    return {
      db: null, // define a instância do Firestore como nula
      error: false,
      loading: false,
      address: {
        zipcode: "",
        state: "",
        city: "",
        street: "",
        neighborhood: "",
        street_number: "",
        complement: "",
        country: "br"
      },
      rules: {
        required: (value) => !!value || "Obrigatório.",
      },
      addressValid: false, // nova propriedade
    };
  },
  props: {
    scrollPosition: Number,
    orderId: {
      type: String,
      required:true
    }
  },

  computed: {
   // ...mapGetters("googleAds", ["advertises"]),
  },

  created() {
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(this.$db, `profile/${user.uid}`);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          // Extrai o valor do dado do documento
          const companyname = doc.data().companyname;
          const description = doc.data().website;

          // Atualiza a propriedade do componente com o valor do dado
          this.companyname = companyname;
          this.description = description;
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o documento: ", error);
      });
  },

  destroyed() {},
  mounted(){
    this.checkAddress()
  },
  methods: {
    checkAddress(){
      this.checkAddressToOrder(this.orderId).then((res)=>{
          if(res === true){
          this.$router.push(`/price/${this.orderId}`);
          }
        })
    },
    async sendData() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

      let address = this.address;
    //  address.uid = user.uid
      address.orderId = this.orderId

      try {
        
        const orderRef = doc(this.$db, `orders/${this.orderId}`);
         // const orderDoc  =  await  getDoc(orderRef)
         // const orderData = orderDoc.data()
      await  updateDoc(orderRef, {address:address}).then(()=>{
        this.$router.push(`/price/${this.orderId}`);
            })
        // Cria referência ao documento de endereço do usuário no Firestore
       // const addressRef = doc(this.$db, `address/${user.uid}`);
       
        // Salva os dados de endereço no documento de endereço
 

        //console.log(addressRef, address);

        // Redireciona para a próxima página

       
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async checkAddressToOrder(orderId){
    // return new Promise((resolve, reject)=>{
        try{
        const auth = getAuth();
        const user = auth.currentUser;
        console.log('ORDER ADDRESS data', orderId)
        if (user && user.uid) {
            const orderRef = doc(this.$db, `orders/${orderId}`);
          //   const orderCollection = collection(this.$db, "addr");
          //   const q =  query(
          //     orderCollection,
          //     where("id","==",orderId),
          //     where("uid", "==", user.uid)
          //   );
          // const querySnapshot =   getDocs(q);
          const orderDoc  =  await  getDoc(orderRef)
          const orderData = orderDoc.data()
          console.log('ORDER ADDRESS data', orderData)
          return(!!orderData.address)
       }
       }catch(err){
       // reject(err)
       }
   //   }) 
    },

    ...mapActions("googleAds", ["setAddress", "setNumber", "setComplement"]),

    confirmCep() {
      cep(this.address.zipcode)
        .then((address) => {
          this.address.state = address.state;
          this.address.city = address.city;
          this.address.street = address.street;
          this.address.neighborhood = address.neighborhood;

          // send address to store
          this.setAddress(this.address);
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },

    validateAddress() {
      console.log(this.address.number);
      if (this.address.number) {
        this.addressValid = true;
      } else {
        this.addressValid = false;
      }
    },

    setAdress(value) {
      switch (value) {
        case "state":
          this.state = value;
          break;
        case "city":
          this.city = value;
          break;
        case "street":
          this.street = value;
          break;
        case "neighborhood":
          this.neighborhood = value;
          break;
        default:
          break;
      }
    },

    redirectLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  padding-top: 2rem;
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 117px;
  padding-bottom: 118px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
