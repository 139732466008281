<template>
  <v-form>
    <v-stepper v-model="stepper">
      <v-stepper-header>
        <v-stepper-step :complete="stepper > 1" step="1" color="#1a0859">
          Objetivos
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 2" step="2" color="#1a0859">
          Canais digitais
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="stepper > 3" step="3" color="#1a0859">
          Descrições
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-content step="1">
        <div class="d-flex justify-center">
          <p class="--subtitle">{{ $t("dialog.steptwotitle") }}</p>
        </div>
        <v-card class="pa-6">
          <v-row class="d-flex align-center pa-2">
            <v-col>
              <v-select
                v-model="objective"
                clearable
                background-color="white"
                :items="items"
                @change="setObjective(objective)"
                label="Objetivo da campanha"
                placeholder="Selecione"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn dark @click="stepper = 2" color="#1ab873"> Continuar </v-btn>
          </v-row>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="pa-6">
          <div class="d-flex justify-center align-center">
            <p class="--subtitle">{{ $t("dialog.steponetitle") }}</p>
          </div>
          <div class="d-flex justify-center align-center">
            <p class="--p">Preencha ao menos dois dos quatro campos</p>
          </div>
          <v-row>
            <v-col class="d-flex flex-column">
              <v-text-field
                label="Nome da empresa"
                v-model="companyname"
                clearable
                background-color="white"
                placeholder="Nome de sua empresa"
                outlined
                filled
                dense
                @keyup="setCompanyName(companyname), validateCompanyName()"
              ></v-text-field>
              <v-text-field
                :rules="[
                  (value) =>
                    /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value) ||
                    'URL inválida',
                ]"
                v-model="website"
                clearable
                background-color="white"
                placeholder="Seu website"
                Label="Insira seu website"
                hint="www.suapagina.com/"
                outlined
                filled
                dense
                @keyup="setWebsite(website)"
              ></v-text-field>
              <v-text-field
                :rules="[
                  (value) =>
                    /^((https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*)$/i.test(
                      value
                    ) || 'URL do Facebook inválida',
                ]"
                v-model="facebook"
                clearable
                background-color="white"
                placeholder="Seu facebook"
                outlined
                filled
                dense
                @keyup="setFacebook(facebook)"
              ></v-text-field>
              <v-text-field
                v-model="instagram"
                clearable
                background-color="white"
                placeholder="Seu Instagram"
                outlined
                filled
                dense
                @keyup="setInstagram(instagram)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <div class="pa-2">
              <v-btn color="#333333" outlined @click="stepper = 1">
                <span style="color: #333333">Voltar</span>
              </v-btn>
            </div>
            <div class="pa-2">
              <v-btn
                :disabled="!companynameValid"
                color="#1ab873"
                @click="nextStep()"
              >
                <span style="color: #ffffff">Continuar</span>
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="pa-6">
          <div class="d-flex justify-center align-center">
            <p class="--subtitle">{{ $t("dialog.stepfourtitle") }}</p>
          </div>
          <v-row class="d-flex flex-column">
            <v-col>
              <v-textarea
                v-model="description"
                clearable
                background-color="white"
                placeholder="Digite aqui..."
                outlined
                block
                dense
                @keyup="setDescription(description), validateDescription()"
              ></v-textarea>
              <p
                v-html="$t('dialog.stepfourdescription')"
                class="--p mt-n4"
              ></p>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <div class="pa-2">
              <v-btn color="#333333" outlined @click="stepper = 2">
                <span style="color: #333333">Voltar</span>
              </v-btn>
            </div>
            <div class="pa-2">
              <v-btn
                dark
                :disabled="!descriptionValid"
                :loading="loading"
                color="#1ab873"
                @click="requestResponse()"
              >
                <span style="color: #ffffff">Continuar</span>
              </v-btn>
            </div>
          </v-row>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { required, url } from "vuelidate/lib/validators";
import { getAuth } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';

export default {
  name: "PopupForm",

  data() {
    return {
      companyname: "",
      website: "https://www.",
      description: "",
      dialog: true,
      stepper: 1,
      facebook: "https://www.fb.com/",
      instagram: "@",
      objective: "",
      items: [
        "Mais visitas ao seu site",
        "Mais vendas no seu site",
        "Mais vistas/vendas nas suas redes sociais",
        "Mais visitas a sua loja física",
        "Mostrar a sua marca (awareness)",
        "Mais ligações telefônicas",
        "Mais visitas para seu canal do Youtube",
      ],
      loading: false,
      resposta: "",
      validations: {
        website: {
          required,
          url,
        },
      },
      companynameValid: false, // nova propriedade
      descriptionValid: false, // nova propriedade
    };
  },

  computed: {},

  methods: {
    ...mapActions("googleAds", [
      "setCompanyName",
      "setDescription",
      "setAdvertises",
      "setObjective",
      "setWebsite",
      "setFacebook",
      "setInstagram",
    ]),

    validateCompanyName() {
      if (this.companyname) {
        this.companynameValid = true;
      } else {
        this.companynameValid = false;
      }
    },

    validateDescription() {
      if (this.description) {
        this.descriptionValid = true;
      } else {
        this.descriptionValid = false;
      }
    },
    
    async checkAddressExistis(){
        try{
          const auth = getAuth();
          const user = auth.currentUser;
          if (!user || !user.uid) {
            return false
          }
          const addressRef = doc(this.$db, `address/${user.uid}`);
          const addressDoc =  await  getDoc(addressRef)
          const address = addressDoc.data()
          console.log('getAddress', address)
         return !!address
        }catch(err){
         console.log('Address not found', err)
        }
      },

    async requestResponse() {
      this.loading = true;
      try {
        // const auth = getAuth();
        // const user = auth.currentUser;
        // if (!user || !user.uid) {
        //   this.$router.push("/where");
        // }
        // if (this.checkAddressExistis() == false) {
        //   console.log("REDIRECT TO ADDRESSS")
        //   this.$router.push("/where");
        // }
        // console.log('SET ADVERTISERS')
        await this.setAdvertises({
          companyname: this.companyname,
          description: this.description,
        }).then(() => {
          //setTimeout(() => {
            this.loading = false;
            this.$emit("popupform:close");
            this.$router.push("/advertises");
        //  }, 13000);
        });
      } catch (error) {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        } else {
          console.log(error.message);
        }
      }
    },

    nextStep() {
      this.stepper = 3;
    },
  },
};
</script>

<style scoped lang="scss">
.--p {
  font-size: 12px;
  color: #221b15;
}
.--h2 {
  font-size: 20px;
  color: #000000;
}
.--title {
  font-size: 20px;
  font-weight: 700;
  color: #221b15;
}
.--subtitle {
  font-size: 20px;
  font-weight: 600;
  color: #221b15;
}
</style>
