export const SET_COMPANY_NAME = "GOOGLEADS/SET_COMPANY_NAME";
export const SET_DESCRIPTION = "GOOGLEADS/SET_DESCRIPTION";
export const SET_ADVERTISES = "GOOGLEADS/SET_ADVERTISES";
export const SET_OBJECTIVE = "GOOGLEADS/SET_OBJECTIVE";
export const SET_WEBSITE = "GOOGLEADS/SET_WEBSITE";
export const SET_FACEBOOK = "GOOGLEADS/SET_FACEBOOK";
export const SET_UID = "GOOGLEADS/SET_UID";
export const SET_INSTAGRAM = "GOOGLEADS/SET_INSTAGRAM";
// export const SET_UID = "GOOGLEADS/SET_UID";

//Adress
export const SET_ADDRESS = "GOOGLEADS/SET_ADDRESS";
export const SET_NUMBER = "GOOGLEADS/SET_NUMBER";
export const SET_COMPLEMENT = "GOOGLEADS/SET_COMPLEMENT";

//Advertise complements
export const SET_ADVERTISEDATES = "GOOGLEADS/SET_ADVERTISEDATES";
export const SET_BUDGET = "GOOGLEADS/SET_BUDGET";
export const SET_TECHFEVALUE = "GOOGLEADS/SET_TECHFEVALUE";
export const SET_FINALPRICE = "GOOGLEADS/SET_FINALPRICE";

// Advertises
export const SET_NEW_ADVERTISE = "GOOGLEADS/SET_NEW_ADVERTISE";
export const UPDATE_ADVERTISE = "GOOGLEADS/UPDATE_ADVERTISE";
export const SET_DESTINATION = "GOOGLEADS/SET_DESTINATION";