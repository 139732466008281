<template>
  <v-row>
    <v-col class="d-flex justify-space-around align-center col-md-12">
      <v-select
        class="pr-2 pl-2"
        v-model="selectedDay"
        label="Dias"
        :items="days"
        outlined
        default="Todos os dias"
        style="width: 200px;"
      ></v-select>
      <v-text-field
        class="pr-2 pl-2"
        type="time"
        v-model="starthour"
        label="Início"
        outlined
        default="08:00"
        style="width: 200px;"
      ></v-text-field>
      <div><p class="--adstext">até</p></div>
      <v-text-field
        class="pr-2 pl-2"
        type="time"
        v-model="finalhour"
        label="Término"
        outlined
        default="22:00"
        style="width: 200px;"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "matrix-hours",
  props: {
    matrix: {
      type: Object,
    },
  },
  data() {
    return {
      days: [
          "Todos os dias",
          "Segunda a Sexta",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sábado",
          "Domingo",
      ],
      selectedDay: "Todos os dias",
      starthour: "",
      finalhour: "",
    };
  },
};
</script>

<style scoped lang="scss">
.--imput-field {
  width: 200px;
}
</style>