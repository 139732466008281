<template>
  <v-form>
    <v-container class="pr-16 pl-16">
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-column justify-center text-center"
        >
          <h1 class="--h1">Criar uma nova conta</h1>
          <p class="--p">
            Preencha abaixo todas as informações necessárias para prosseguir:
          </p>
        </v-col>
      </v-row>

      <!-- Desktop -->

      <v-row
        v-if="!$root.isMobile"
        style="margin-left: 10rem; margin-right: 10rem"
        class="d-none d-sm-flex flex-column"
      >
        <v-col cols="12">
          <v-form v-model="valid">
            <v-card-text>
              <v-btn outlined block color="black" @click="loginPage()">
                <span style="color: black"
                  >Já possui uma conta? Faça o login</span
                >
              </v-btn>
              <v-text-field
                v-model="name"
                required
                :rules="[rules.required]"
                class="mt-6"
                outlined
                background-color="white"
                dense
                clearable
                label="Insira seu nome"
                counter="50"
              >
              </v-text-field>
              <v-text-field
                v-model="email"
                required
                :rules="[rules.required, rules.email]"
                outlined
                background-color="white"
                dense
                clearable
                label="Insira seu e-mail"
              >
              </v-text-field>
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="password"
                :rules="[rules.required, rules.min]"
                outlined
                background-color="white"
                dense
                :type="show1 ? 'text' : 'password'"
                hint="Sua senha parece boa"
                required
                clearable
                label="Insira uma senha segura"
                counter
                @click:append="show1 = !show1"
              >
              </v-text-field>
              <v-text-field
                v-model="passwordConfirm"
                outlined
                background-color="white"
                dense
                clearable
                label="Confirme sua senha"
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.confirmRule(password)]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                counter
                @click:append="show1 = !show1"
              >
              </v-text-field>
              <v-text-field
                :rules="[rules.required]"
                v-model="phone"
                outlined
                required
                v-mask="['+55 ## #####-####']"
                background-color="white"
                dense
                clearable
                label="Insira seu telefone"
              >
              </v-text-field>
              <v-text-field
                :rules="[rules.required, rules.min11, rules.max11]"
                v-model="document"
                required
                v-mask="['###.###.###-##']"
                outlined
                background-color="white"
                dense
                clearable
                label="Insira seu CPF"
              >
              </v-text-field>
              <v-btn
                block
                :disabled="!valid"
                :loading="processing"
                color="#1ab873"
                @click="createAccount()"
              >
                <span style="color: white">Criar conta</span>
              </v-btn>
            </v-card-text>
          </v-form>
        </v-col>
      </v-row>

      <!-- Mobile -->

      <v-row v-if="$root.isMobile" class="d-sm-none">
        <v-col cols="12">
          <v-form v-model="valid">
            <v-card-text>
              <v-btn @click="loginPage()" outlined block color="black">
                <span style="color: black"
                  >Já possui uma conta? Faça o login</span
                >
              </v-btn>
              <v-text-field
                v-model="name"
                required
                :rules="[rules.required]"
                class="mt-6"
                outlined
                background-color="white"
                dense
                clearable
                label="Insira seu nome"
                counter="50"
              >
              </v-text-field>
              <v-text-field
                v-model="email"
                required
                :rules="[rules.required, rules.email]"
                outlined
                background-color="white"
                dense
                clearable
                label="Insira seu e-mail"
              >
              </v-text-field>
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="password"
                :rules="[rules.required, rules.min]"
                outlined
                background-color="white"
                dense
                :type="show1 ? 'text' : 'password'"
                hint="Sua senha parece boa"
                required
                clearable
                label="Insira uma senha segura"
                counter
                @click:append="show1 = !show1"
              >
              </v-text-field>
              <v-text-field
                v-model="passwordConfirm"
                outlined
                background-color="white"
                dense
                clearable
                label="Confirme sua senha"
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.confirmRule(password)]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                counter
                @click:append="show1 = !show1"
              >
              </v-text-field>
              <v-text-field
                :rules="[rules.required]"
                v-mask="['+55 ## #####-####']"
                v-model="phone"
                outlined
                required
                background-color="white"
                dense
                clearable
                label="Insira seu telefone"
              >
              </v-text-field>
              <v-text-field
                :rules="[rules.required, rules.min11, rules.max11]"
                v-mask="['###.###.###-##']"
                v-model="document"
                required
                outlined
                background-color="white"
                dense
                clearable
                label="Insira seu CPF"
              >
              </v-text-field>
              <v-btn
                block
                :disabled="!valid"
                :loading="processing"
                color="#1ab873"
                @click="createAccount()"
              >
                <span style="color: white">Criar conta</span>
              </v-btn>
            </v-card-text>
          </v-form>
        </v-col>
      </v-row>

      <v-row class="pt-16"> </v-row>

      <v-dialog
        v-model="errorDocument"
        persistent
        max-width="300"
        max-height="300"
      >
        <v-card class="pt-4">
          <v-card-text>
            <v-img
              src="../assets/canal-do-anuncio.svg"
              contain
              max-height="30"
              width="100%"
            >
            </v-img>
            <v-card-title><b>Falha no cadastro</b></v-card-title>
            <v-card-text
              >CPF já cadastrado em nosso sistema. Faça o login em sua conta ou
              crie um novo usuário.</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="errorDocument = false">
                Tentar novamente
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="error" persistent max-width="300">
        <v-card class="pt-4">
          <v-card-text>
            <v-img
              src="../assets/canal-do-anuncio.svg"
              contain
              max-height="30"
              width="100%"
            >
            </v-img>
            <v-card-title><b>Falha no cadastro</b></v-card-title>
            <v-card-text
              >Email já cadastrado em nosso sistema. Faça o login em sua conta
              ou crie um novo usuário</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="error = false">
                Tentar novamente
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="success" persistent max-width="480" >
        <v-card class="pt-3">
          <v-img
            contain
            src="https://lion-web-site.s3.amazonaws.com/public/signup-success-cdm.svg"
            width="400"
            class="mb-3 mt-6 mx-auto"
          >
          </v-img>
          <v-card-title>Cadastro criado com sucesso</v-card-title>
          <v-card-text
            >Enviamos uma mensagem de validação para o e-mail cadastrado.
            Confirme agora para ativar sua conta!</v-card-text
          >
          <v-card-actions>
            <v-btn
              color="primary"
              lg
              class="mx-auto mb-3"
              elevation="0"
              @click="closeDialog()"
            >
              Continuar<v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
import {
  collection,
  setDoc,
  updateDoc,
  doc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { mapGetters } from "vuex";
// import moment from "moment";

export default {
  name: "create-account",
  components: {},

  data() {
    return {
      orderId: null,
      processing: false,
      userId: null,
      user: "",
      show1: false,
      show2: false,
      errorDocument: false,
      error: false,
      errors:{
        text: null,
        show: false
      },
      success: false,
      valid: false,
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      phone: "",
      document: "",
      // objective: null,
      // website: null,
      // facebook: null,
      // companyname: null,
      // instagram: null,
      // description: null,
      // advertises: null,
      rules: {
        required: (value) => !!value || "Obrigatório.",
        min: (v) => v.length >= 6 || "Min 6 caractéres",
        max14: (v) => v.length <= 18 || "Max 14 números",
        max11: (v) => v.length <= 14 || "Max 14 números",
        min11: (v) => v.length >= 14 || "Min 11 números",
        emailMatch: () => `O email e a senha não são compatíveis`,
        counter: (value) => value.length <= 20 || "Max 20 caractéres",
        confirmRule(password) {
          return (v) => password == v || "As senhas não estão iguais";
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail inválido";
        },
      },
      db: null, // define a instância do Firestore como nula
    };
  },

  props: {
    scrollPosition: Number,
  },

  computed: {
    ...mapGetters("googleAds", ["googleAds"]),
  },

  created() {},

  destroyed() {},

  methods: {

    // Enviar Lead para o Zoho e Plataforma
    submitForm(userFirebaseId) {
      this.loading = true;
      const headers = {
        "Origin-Request": "aionlineads.canaldoanuncio.com",
      };
      const url = `/users/register/advertiser`;
      localStorage.removeItem('@aioads:token')
      const form = {
        name: this.name,
        company: this.googleAds.companyname,
        contact: this.phone,
        email: this.email,
        password: this.password,
        passwordConfirm: this.passwordConfirm,
        firebaseid: userFirebaseId,
        credential:true
      };
      this.$http
        .post(url, form, {
          headers,
        })
        .then(
          (res) => {
           // this.createAccount(res);
            const grantToken = res.data.data.security.grantToken
            this.updateTokenUser(this.userId, grantToken)
            this.processing = false;
            this.success = true;
          },
          (err) => {
            this.processing = false;
              this.error = true;
            const errors = err.response.data.errors;
            const errorNames = Object.values(errors[0]);
            this.loading = false;
            this.errors.text = errors.length ? errorNames[0][0] : "";
            this.errors.show = true;
            console.error("err", err.response);
          }
        );
    },
    async updateTokenUser(uid, token){
      console.log('user token ', token)
      const userRef = doc(this.$db, `profile/${uid}`);
      localStorage.setItem('@aioads:token',token)
      await updateDoc(userRef, { accessToken: token }).then(()=>{
        console.log('token updated')
      });
    },
    createAccount() {
      //console.log('return from created user', res)
      this.processing = true;
      const auth = getAuth();
      const password = this.password;
      const email = this.email;
      this.checkDocument().then((querySnapshot) => {
        if (!querySnapshot) {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              this.userId = userCredential.user.uid;
              this.saveLead();
              this.submitForm(this.userId)
              this.user = userCredential.user;
              this.$data.currentUser = userCredential.user;
            })
            .catch((error) => {
              console.error("Erro ao criar a conta: ", error);
              this.processing = false;
              this.error = true;
            });
        } else {
          this.processing = false;
          this.errorDocument = true;
        }
      });
    },

    async checkDocument() {
      const db = this.$db;
      const profileCollection = collection(db, "profile");
      const q = query(
        profileCollection,
        where("document", "==", this.document)
      );
      const querySnapshot = await getDocs(q);
      // console.log(querySnapshot);
      let result = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        result.push(doc.id);
      });
      return !!result.length;
    },

    saveLead() {

      this.processing = true;
      console.log("registering account...");
      // obtém a referência à coleção "profile

      if (this.valid) {
        const dataAccount = {
          uid: this.userId,
         //  zohoAccountId: res.data.data.zohoAccountId,
         // zohoContactId: res.data.data.zohoContactId,
        //  token: data.grantToken,
          name: this.name,
          phone: this.phone,
          document: this.document,
          email: this.email,
          website: this.googleAds.website,
          facebook: this.googleAds.facebook,
          companyname: this.googleAds.companyname,
          instagram: this.googleAds.instagram,
          description: this.googleAds.description,
          isActive: true
        };

        // const dataOrder = {
        //   uid: this.userId,
        //   finalPrice: 0,
        //   status: "pending",
        //   budget: 0,
        //   zohoDealId: null,  //res.data.data.zohoDealId,
        //   objective: this.googleAds.objective,
        //   companyname: this.googleAds.companyname,
        //   website: this.googleAds.website,
        //   description: this.googleAds.description,
        //   createdOn: moment().format(),
        //   advertises: this.googleAds.advertises,
        // };

        // Cria referência ao documento do usuário no Firestore
        // const db = this.$db;
        // const profileCollection = collection(db, "profile");
        // const userDocRef = doc(profileCollection, this.userId);
        const userDocRef = doc(this.$db, `profile/${this.userId}`);

        // Salva os dados do usuário no documento
        setDoc(userDocRef, dataAccount)
          .then(() => {
            // const orderCollection = collection(this.$db, "orders");
            // const orderId = moment().format("X");
            // const orderDocRef = doc(orderCollection, orderId);
            // this.orderId = orderId;
            // setDoc(orderDocRef, dataOrder).then(() => {
            //   console.log("Dados salvos com sucesso!");
            //   this.processing = false;
            //   this.success = true;
            // });
          })
          .catch((error) => {
            console.error("Erro ao salvar os dados: ", error);
            this.processing = false;
            this.error = true;
          });
      }
    },

    closeDialog() {
      this.error = false;
      this.success = false;
      this.$router.push(`/advertises`);
    },

    loginPage() {
      this.$router.push("/login");
    },

    submit() {
      if (this.name && this.email && this.phonenumber) {
        console.log("prepare to send.");
        var form = document.getElementById("formsubmit");
        form.click();
      } else {
        alert("Preencha todos os campos, por favor.");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  font-size: 18px;
  color: #333333;
}
.--adstext {
  padding-top: 2rem;
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 193px;
  padding-bottom: 193px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
