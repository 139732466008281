<template>
  <v-container class="pr-16 pl-16">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="d-flex flex-column justify-center text-center"
      >
        <h1 v-html="$t('phonenumber.title')" class="--h1"></h1>
        <p v-html="$t('phonenumber.subtitle')" class="--p"></p>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column">
      <v-col>
        <v-text-field
          dense
          v-mask="['+55 ## #####-####']"
          v-model="companyphone"
          label="Insira telefone"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col> </v-col>
      <v-col>
        <v-btn :loading="loading" color="#1ab873" block @click="sendData()">
          <span style="color: white">Continuar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pb-16"></v-row>

    <v-dialog v-model="errorPopup" persistent max-width="300">
      <v-card class="pt-4">
        <v-img
          src="../assets/canal-do-anuncio.svg"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>Erro ao encontrar palavras chave</v-card-title>
        <v-card-text
          >Clique no botão abaixo e tente novamente</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="tryAgain()">
            Tentar novamente
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

export default {
  name: "phonecalls-page",

  data() {
    return {
      loading: false,
      companyphone: "",
      errorPopup: false
    };
  },
  props: {
    scrollPosition: Number,
    snap: Object,
  },
  created() {
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(this.$db, `profile/${user.uid}`);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          // Extrai o valor do dado do documento
          const companyname = doc.data().companyname;
          const description = doc.data().website;

          // Atualiza a propriedade do componente com o valor do dado
          this.companyname = companyname;
          this.description = description;
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o documento: ", error);
      });
  },
  mounted() {},
  destroyed() {},

  methods: {
    // sendData() {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.loading = false;
    //     this.$router.push("/price");
    //   }, 5000);
    // },

    tryAgain() {
      this.errorPopup = false;
      window.location.reload();
    },

    async sendData() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

      const companyphone = this.companyphone;

      try {
        // Cria referência ao documento de endereço do usuário no Firestore
        const companyphoneRef = doc(this.$db, `profile/${user.uid}`);

        // Salva os dados de endereço no documento de endereço
        await updateDoc(companyphoneRef, {
          companyphone,
        });

        // Redireciona para a próxima página
        this.$router.push("/price");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 117px;
  padding-bottom: 118px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
