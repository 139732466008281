import * as types from "./mutation-types";
// import moment from "moment";

export default {

  [types.SET_COMPANY_NAME](state, companyname) {
    state.googleAds.companyname = companyname
  },

  [types.SET_UID](state, uid) {
    state.googleAds.uid = uid
  },

  [types.SET_DESCRIPTION](state, description) {
    state.googleAds.description = description
  },

  [types.SET_ADVERTISES](state, advertises) {
    console.log(state, advertises)
    state.googleAds.advertises = advertises
  },

  [types.SET_OBJECTIVE](state, objective) {
    state.googleAds.objective = objective
  },

  [types.SET_WEBSITE](state, website) {
    state.googleAds.website = website
  },

  [types.SET_FACEBOOK](state, facebook) {
    state.googleAds.facebook = facebook
  },

  [types.SET_INSTAGRAM](state, instagram) {
    state.googleAds.instagram = instagram
  },

  [types.SET_ADDRESS](state, address) {
    state.googleAds.address = address
  },

  [types.SET_NUMBER](state, number) {
    state.googleAds.address.number = number
  },

  [types.SET_COMPLEMENT](state, complement) {
    state.googleAds.address.complement = complement
  },

  [types.SET_ADVERTISEDATES](state, advertiseDates) {
    state.googleAds.advertiseDates = advertiseDates
  },

  [types.SET_BUDGET](state, budget) {
    state.googleAds.budget = budget
  },
  [types.SET_TECHFEVALUE](state, techFee) {
    state.googleAds.techFeeValue = techFee
  },
  [types.SET_FINALPRICE](state, finalPrice) {
    state.googleAds.finalPrice = finalPrice
  },

  [types.SET_DESTINATION](state, destination) {
    state.googleAds.destination = destination
  },

  [types.SET_NEW_ADVERTISE](state, newAdvertise) {
    state.googleAds.advertises.push(newAdvertise)
  },

  [types.UPDATE_ADVERTISE](state, advertise) {
    let idx = state.googleAds.advertises.findIndex((item) => {
      console.log(item.id,'===', advertise.id, advertise)
      return  item.id === advertise.id 
      })
    console.log('idx', idx)
    if (idx > -1) {
      state.googleAds.advertises[idx] = advertise
    }
  },

}