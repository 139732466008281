<template>
  <v-container class="pr-10 pl-10">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="d-flex flex-column justify-center text-center"
      >
        <h1 v-html="$t('website.title')" class="--h1"></h1>
        <p v-html="$t('website.subtitle')" class="--p"></p>
      </v-col>
    </v-row>
    <v-row>
      <v-text-field
        v-model="website"
        :value="[googleAds.googleAds.website]"
        label="Página que receberá o tráfego"
        outlined
        disabled
      ></v-text-field>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :loading="loading" color="#1ab873" block @click="sendData()">
          <span style="color: white">Continuar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pb-16"></v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from "vuex";

export default {
  name: "website-page",

  data() {
    return {
      loading: false,
    };
  },
  props: {
    scrollPosition: Number,
  },
  created() {},
  mounted() {},
  destroyed() {},

  computed: {
    ...mapGetters("googleAds", ["website"]),

    destination: {
      get() {
        return this.website;
      },
      set(value) {
        this.setWebsite(value);
      },
    },
  },

  methods: {
    ...mapActions("googleAds", ["setWebsite"]),

    updateWebsite(event) {
      this.website = event.target.value;
    },

    sendData() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/price");
      }, 5000);
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  padding-top: 2rem;
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 117px;
  padding-bottom: 118px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
