<template>
  <v-main>
    <v-card class="d-flex" color="white">
      <v-navigation-drawer permanent expand-on-hover>
        <v-list nav dense>
          <v-list-item link @click="selectProfile()">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="selectCampaigns()">
            <v-list-item-icon>
              <v-icon>mdi-cart-arrow-down</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Minhas campanhas</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="d-flex">
        <ProfileData></ProfileData>
        <Address></Address>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import ProfileData from "../components/welcomePage/ProfileData.vue";
import Address from "../components/welcomePage/Address.vue";
export default {
  data() {
    return {
        loading: false
    };
  },
  components: {
    ProfileData,
    Address,
  },
  name: "welcome-page",
  methods: {
    selectProfile() {
      setTimeout(() => {
        this.$router.push("/profile");
      }, 1000);
    },

    selectCampaigns() {
      setTimeout(() => {
        this.$router.push("/myads");
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 3rem;
  color: #ffffff;
}
.--p {
  padding: 2rem;
}
@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
