<template>
  <v-main style="background-color: #DAF7F7;">
  <FirstSection />
  <SecondSection />
  <ThirdSection />
  <FourthSection />
  <FifhtSection />
  </v-main>
</template>

<script>
  import FirstSection from '../components/home/FirstSection'
  import SecondSection from '../components/home/SecondSection'
  import ThirdSection from '../components/home/ThirdSection'
  import FourthSection from '../components/home/FourthSection'
  import FifhtSection from '../components/home/FifthSection'

  export default {
    name: 'home-page',

    components: {
      FirstSection,
      SecondSection,
      ThirdSection,
      FourthSection,
      FifhtSection,
    },
  }
</script>
