<template>
  <v-card color="#f4fff0"
    ><v-card-text>
      <div class="d-flex justify-space-around align-center">
        <h3>Anúncio</h3>
        <div class="d-flex align-center">
          <h3 class="--subtitle pr-2">Desativar anúncio</h3>
          <v-switch disabled v-model="cloneAdvertise.disabled"></v-switch>
        </div>
      </div>
      <template>
        <v-card>
          <v-card-text>
            <div>
              <v-icon>mdi-search</v-icon>
            </div>
            <div>
              <template  v-if="editing">
              <v-text-field               
                v-model="cloneAdvertise.title"
                outlined
                counter="30"
                label="Título"
              ></v-text-field>
            </template>
            <template  v-else>
              <div               
                class="--title"
                v-html="cloneAdvertise.title"
              ></div>
            </template>
              <br />
              <template  v-if="editing">
              <v-textarea
                v-model="cloneAdvertise.description"
                outlined
                counter="90"
                label="Descrição de anúncio"
              ></v-textarea>
              </template>
              <template  v-else>
              <div
                class="--description"
                v-html="cloneAdvertise.description"
              ></div>
              </template>
            </div>
            <div class="--bottom"></div>
            <div>
              <v-btn :color=" editing ? 'green': 'black'" class="white--text" block @click="saveAds()"
                >
                {{ editing ? 'Salvar editção' : 'Editar anúncio' }}
              </v-btn>
            </div>
            <!-- <div class="d-flex justify-center">
            <p><i>Realize o login para editar o anúncio</i></p>
            </div> -->
          </v-card-text>
        </v-card>
      </template></v-card-text
    ></v-card
  >
</template>

<script>

import { getAuth } from "firebase/auth";

export default {
  name: "advertise-block",
  data() {
    return {
      isEdited: false,
      editing: false,
      cloneAdvertise: {
        title:null,
        keywords: [],
        description:null,
        disabled: true
      }
    };
  },

  props: {
    advertise: Object,
    scrollPosition: Number,
    
  },

  computed: {},

  created() {
    this.cloneAdvertise = JSON.parse(JSON.stringify(this.advertise))
  },
  mounted() {},
  destroyed() {},

  methods: {
    saveAds() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.$router.push("/login");
      }
       this.editing = !this.editing
      if(this.editing == false){
       this.$emit("save-ads", this.cloneAdvertise)
      }
    },

    sendAds() {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        this.$router.push("/login");
      } else {
      //  this.saveAdvertises();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--title {
  padding-top: 1rem;
  font-size: 21px;
  color: #333333;
  font-weight: 600;
}
.--description {
  padding-top: 1rem;
  font-size: 18px;
  color: #333333;
}
.--bottom {
  padding-bottom: 4rem;
}
.--form {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
