<template>
  <v-container class="pr-10 pl-10">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="d-flex flex-column justify-center text-center"
      >
        <h1 v-html="$t('price.title')" class="--h1"></h1>
        <p v-html="$t('price.subtitle')" class="--p"></p>
      </v-col>
    </v-row>

    <!-- Mobile -->

    <v-row class="d-flex flex-column d-sm-none">
      <v-col class="d-flex justify-space-around align-center">
        <v-select
          v-model="budget"
          
          label="Investimento mensal"
          outlined
          :items="budgetOptions"
          prefix="R$"
          hint="Valor mínimo de R$2000"
        ></v-select>
      </v-col>
      <v-col class="d-flex flex-column justify-space-around align-center">
        <p v-html="$t('price.advantages')" class="--adstext"></p>
        <template>
          <v-expansion-panels :value="panelIndex">
            <v-expansion-panel :active-class="'green lighten-5'">
              <v-expansion-panel-header
                ><h4>Pacote 1</h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Acompanhamento mensal personalizado<v-divider
                  class="pa-2"
                ></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Relatórios de performance<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Visualização online da campanha na plataforma do
                Google<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Reunião mensal de acompanhamento de performance e briefing para
                novas campanhas
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :active-class="'green lighten-5'">
              <v-expansion-panel-header
                ><h4>Pacote 2</h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Acompanhamento mensal personalizado<v-divider
                  class="pa-2"
                ></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Relatórios de performance<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Visualização online da campanha na plataforma do
                Google<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Reunião mensal de acompanhamento de performance e briefing para
                novas campanhas
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :active-class="'green lighten-5'">
              <v-expansion-panel-header
                ><h4>Pacote 3</h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Acompanhamento mensal personalizado<v-divider
                  class="pa-2"
                ></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Relatórios de performance<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Visualização online da campanha na plataforma do
                Google<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Reunião mensal de acompanhamento de performance e briefing para
                novas campanhas
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-col>
    </v-row>

    <!-- Desktop -->

    <v-row class="d-none d-sm-flex">
      <v-col class="d-flex flex-column justify-space-around">
        <v-select
          v-model="budget"
         
          label="Investimento mensal"
          outlined
          :items="budgetOptions"
          prefix="R$"
        ></v-select>
        <p v-html="$t('price.advantages')" class="--adstext pt-2"></p>
        <template>
          <v-expansion-panels :value="panelIndex">
            <v-expansion-panel :active-class="'green lighten-5'">
              <v-expansion-panel-header
                ><h4 class="--adstitle">
                  Pacote 1
                  <template
                    ><v-chip class="ml-4" color="#14a3a3"
                      ><span style="color: white; font-weight: 400"
                        >De R$2000 a R$4000 /mês</span
                      ></v-chip
                    ></template
                  >
                </h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Visualização online da campanha na plataforma do Google
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :active-class="'green lighten-5'">
              <v-expansion-panel-header
                ><h4 class="--adstitle">
                  Pacote 2
                  <template
                    ><v-chip class="ml-4" color="#14a3a3"
                      ><span style="color: white; font-weight: 400"
                        >De R$4500 a R$7000 /mês</span
                      ></v-chip
                    ></template
                  >
                </h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Acompanhamento mensal personalizado<v-divider
                  class="pa-2"
                ></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Visualização online da campanha na plataforma do Google
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :active-class="'green lighten-5'">
              <v-expansion-panel-header
                ><h4 class="--adstitle">
                  Pacote 3
                  <template
                    ><v-chip class="ml-4" color="#14a3a3"
                      ><span style="color: white; font-weight: 400"
                        >De R$7500 a R$10000 /mês</span
                      ></v-chip
                    ></template
                  >
                </h4></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Acompanhamento mensal personalizado<v-divider
                  class="pa-2"
                ></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Relatórios de performance<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Visualização online da campanha na plataforma do
                Google<v-divider class="pa-2"></v-divider
                ><v-icon color="black"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Reunião mensal de acompanhamento de performance e briefing para
                novas campanhas
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-center" cols="6"> </v-col>
      <v-col class="d-flex justify-center" cols="6">
        <v-btn
          dark
          block
          :loading="loading"
          color="#1ab873"
          @click="sendData()"
        >
          Ir para pagamento
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col class="d-flex justify-center" cols="12">
      </v-col>
    </v-row> -->

    <v-dialog v-model="error" persistent max-width="300">
      <v-card>
        <v-img
          src="../assets/google-adwords-logo.png"
          contain
          max-height="30"
          width="100%"
        >
        </v-img>
        <v-card-title>Você não está logado</v-card-title>
        <v-card-text
          >Realize o login para para cadastrar um endereço</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="redirectLogin()">
            Realizar login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="pb-16"></v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getFirestore, updateDoc, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default {
  name: "price-page",

  data() {
    return {
      panelIndex: -1,
      db: null, // define a instância do Firestore como nula
      error: false,
      budget: "",
      loading: false,
      errorPopup: false,
      chipKeywords: null,
      status: "",
      statusTitle: false,
      colorTitle: "",
      colorTitle1: "",
      colorTitle2: "",
      budgetOptions: [
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
      ],
      panelRanges: [
        { min: 2000, max: 4000 },
        { min: 4500, max: 7000 },
        { min: 7500, max: Infinity },
      ],
      keywords: [],
    };
  },
  props: {
    scrollPosition: Number,
    id: {
      type: String,
      required: true
    }
  },

  watch: {
    budget(nVal){
       const techFeeValue = (this.googleAds.techFee * nVal)
       nVal =   nVal - techFeeValue
       this.setTechFeeValue(techFeeValue)
       this.setBudget(nVal)
       console.log('set ordr id', this.id)
       this.setFinalPrice(techFeeValue + nVal)
       this.updateOrder({ 
        orderId: this.id, 
        data:{
           techFee:this.googleAds.techFee,
           techFeeValue: techFeeValue,
           budget: nVal,
           finalPrice: techFeeValue + nVal,
          }})
    },
    selectedPanelIndex() {
      this.panelIndex = this.selectedPanelIndex;
    },
  },

  computed: {
    ...mapGetters("googleAds",["googleAds"]),
    selectedPanelIndex() {
      const budget = parseInt(this.budget);
      return this.panelRanges.findIndex(
        (range) => budget >= range.min && budget <= range.max
      );
    },
  },

  created() {
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(this.$db, `profile/${user.uid}`);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          // Extrai o valor do dado do documento
          const companyname = doc.data().companyname;
          const description = doc.data().website;

          // Atualiza a propriedade do componente com o valor do dado
          this.companyname = companyname;
          this.description = description;
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o documento: ", error);
      });
  },

  destroyed() {},

  methods: {
    ...mapActions("googleAds",["setTechFeeValue","setFinalPrice","updateOrder"]),
    // sendData() {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.loading = false;
    //     this.$router.push("/payment");
    //   }, 5000);
    // },
    updateOrderPrice(){

      const orderRef = doc(this.$db, `orders/${this.id}`);
      if(orderRef){
        //const finalPrice = budget;
       // console.log(finalPrice)
       //const adsBudget = budget * 0.8;
       // const amount = budget * 100;

      }
    },

    updateBudgetProfile(){
      const auth = getAuth();
      const user = auth.currentUser;
      let budget = parseInt(this.budget) * 0.8;
      const budgetRef = doc(this.$db, `profile/${user.uid}`);
      let profileData =  budgetRef.data();
          if(profileData.budget){
            budget += profileData.budget
          }
          // Salva os dados de endereço no documento de endereço
          updateDoc(budgetRef, {
            budget,
          });
    },

    async sendData() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

      
     // const finalPrice = budget;
      //const adsBudget = budget * 0.8;
     // const amount = budget * 100;
     // const status = "Inativa";

      // await this.requestKeywords()
      //   .then((keywords) => {
         // console.log(keywords)
          // Cria referência ao documento de endereço do usuário no Firestore
          // const budgetRef = doc(this.$db, `profile/${user.uid}`);
          
          // let profileData =  budgetRef.data();
          // if(profileData.budget){
          //   budget += profileData.budget
          // }
          // // Salva os dados de endereço no documento de endereço
          // updateDoc(budgetRef, {
          //   budget,
          //   status,
          // });
          // Redireciona para a próxima página
          this.$router.replace(`/payment/${this.$route.params.id}`);
        // })
        // .catch((error) => {
        //   console.log(error);
        //   this.loading = false;
        // });
    },

    requestKeywords() {
      return new Promise((resolve, reject) => {
        const companyname = this.companyname;
        const description = this.description;

        this.loading = true;
        Window.$openai
          .createCompletion({
            model: "text-davinci-003",
            prompt: `Como um especialista em Google Ads, criar 10 keywords para um anúncio de search da empresa ${companyname} que trabalha com ${description}. Liste as respostas em um array separados por string sem setar a nenhuma variável para armazenar as keywords.`,
            max_tokens: 700,
            temperature: 0.6,
            top_p: 0.9,
          })
          .then((completion) => {
            console.log("Resposta do OpenAI", completion)
            this.keywords.push(completion.data.choices[0].text);
            const string = this.keywords[0]; // acessando a string dentro do array
            const regex = /"(.*?)"/g;
            console.log ('teste', string)
            const matches = string.match(regex);
            console.log ('matches', matches)
            const keywords = matches
            this.chipKeywords = keywords;
            this.loading = false;
            resolve(keywords);
          })
          .catch((error) => {
            if (error.response) {
              this.errorPopup = true;
              console.log(error.response.status);
              console.log(error.response.data);
            } else {
              this.errorPopup = true;
              console.log(error.message);
            }
            reject(error);
          });
      });
    },

    ...mapActions("googleAds", ["setBudget"]),
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 117px;
  padding-bottom: 118px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
