import * as types from "./mutation-types";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { db } from '../../config/firebase'

import { doc,  updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import axios from "axios";
// import { db } from "@/main";
// import router from "@/router";

// export const getLocalStorage = async ({commit}) => {
//   return new Promise((resolve) => {
//     commit(types.SET_COMPANY_NAME);
//         resolve()
//   })   
// };

export const setCompanyName = async ({ commit }, companyname) => {
  return new Promise((resolve) => {
    commit(types.SET_COMPANY_NAME, companyname);
    resolve()
  })
};

export const setDescription = async ({ commit }, description) => {
  return new Promise((resolve) => {
    commit(types.SET_DESCRIPTION, description);
    resolve()
  })
};

export const setUid = async ({ commit }, uid) => {
  return new Promise((resolve) => {
    commit(types.SET_UID, uid);
    resolve()
  })
};

// export const setAdvertises = async ({ commit }, data) => {
//   const promptTemplate = `Como um especialista em Google Ads, criar um anúncio de search para empresa ${data.companyname} que trabalha com ${data.description} com 3 títulos diferentes com no máximo 26 caracteres cada, 3 descrições resumidas diferentes com no máximo 80 caracteres cada e 10 kerywords por anúncio. Não utilizar exclamação no final. Nunca exceder o limite de caractéres para cada título. Nunca exceder o limite de caractéres nas descrições. Não precisa usar todos os elementos enviados, o importante é não exceder o limite de caractéres. Liste as respostas em um objeto JSON sem ponto no início com título de advertises.`;

//   let prompt = promptTemplate;
//   let completion;
//   let counter = 0;
//   const maxIterations = 3;

//   while (prompt.length > 90 && counter < maxIterations) {
//     completion = await Window.$openai.createCompletion({
//       model: "text-davinci-003",
//       prompt: completion ? prompt : "Resumir este prompt deixando com no máximo 30 caracteres para o título e 90 para descrição de cada um dos anúncios, respeitando as mesmas regras solicitadas anteriormente.",
//       max_tokens: 700,
//       temperature: 0.6,
//       top_p: 0.9,
//     });

//     prompt = promptTemplate + completion.choices[0].text;
//     counter++;
//   }

//   if (prompt.length <= 90) {
//     console.log("Resposta do OpenAI", completion);
//     let openAiRequest = JSON.parse(completion.choices[0].text);
//     let listAdvertises = openAiRequest.advertises.map((advertise) => {
//       advertise.id = uuidv4();
//       advertise.disabled = false;
//       advertise.createdOn = moment().format();
//       return advertise;
//     });
//     console.log(completion.choices[0].text);
//     console.log(listAdvertises);
//     commit(types.SET_ADVERTISES, listAdvertises);
//   } else {
//     console.log("Exceeded the maximum number of iterations without satisfying the length constraint.");
//   }
// };

export const setAdvertises = ({ commit }, data) => {
  return new Promise((resolve, reject)=>{
    const prompt = `Como um especialista em Google Ads `+
    `crie 3 anúncios de search para empresa ${data.companyName} que trabalha com ${data.description}. `+
    `Não utilizar exclamação no final da descrição em no título. `+
    `Para cada anuncio crie um título, uma descrição e keywords `+
    `Use o seguinte objeto JSON de exemplo para cada anúncio { "title": String, "keywords": Array, "description": String }. `+
    `Crie no máximo 10 keywords diferentes para cada anúncio. `+
    `O titulo deve ter no máximo 26 caracteres. `+
    `A descrição deve ser resumida com no máximo 80 caracteres. `+
    `Nunca exceder o limite de caractéres para cada título. `+
    `Nunca exceder o limite de caractéres nas descrições. ` +
    `Não precisa usar todos os elementos enviados, o importante é não exceder o limite de caracteres.`+    
    `A resposta deve ser em JSON em formato de string com uma propriedade advertises contendo todos os anúncios em um array. exemplo: {"advertises":[{"title":String,"keywords":Array,"description":String}]} `;
    
    
    

      Window.$openai.createCompletion({
        model: "text-davinci-003",
    prompt: prompt,//`Como um especialista em Google Ads, criar um anúncio de search para empresa ${data.companyname} que trabalha com ${data.description} com 3 títulos diferentes com no máximo 26 caracteres cada, 3 descrições resumidas diferentes com no máximo 80 caracteres cada e 10 kerywords por anúncio. Não utilizar exclamação no final. Nunca exceder o limite de caractéres para cada título. Nunca exceder o limite de caractéres nas descrições. Não precisa usar todos os elementos enviados, o importante é não exceder o limite de caractéres. Liste as respostas em um objeto JSON sem ponto no início com título de advertises.`,
    max_tokens: 700,
    temperature: 0.6,
    top_p: 0.9,
  }).then(completion => {
    console.log("Resposta do OpenAI", completion)
    const json = completion.data.choices[0].text.replace('\n','').replace('\\"','"')
    console.log("Resposta json", json)
    let openAiRequest = JSON.parse(json)
    console.log("Resposta do OpenAI json", openAiRequest)
    let listAdvertises = openAiRequest.advertises.map((advertise) => {
      advertise.id = uuidv4()
      advertise.disabled = false
      advertise.createdOn = moment().format()
      return advertise
    })
    console.log(completion.data.choices[0].text);
    console.log(listAdvertises)
    commit(types.SET_ADVERTISES, listAdvertises);
        resolve(listAdvertises)
      })
        .catch(error => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
          } else {
            console.log(error.message);
          }
          reject(error)
        });
  })
};

  export const setObjective = async ({ commit }, objective) => {
    return new Promise((resolve) => {
      commit(types.SET_OBJECTIVE, objective);
      resolve()
    })
  };

  export const setWebsite = async ({ commit }, website) => {
    return new Promise((resolve) => {
      commit(types.SET_WEBSITE, website);
      resolve()
    })
  };

  export const setFacebook = async ({ commit }, facebook) => {
    return new Promise((resolve) => {
      commit(types.SET_FACEBOOK, facebook);
      resolve()
    })
  };

  export const setInstagram = async ({ commit }, instagram) => {
    return new Promise((resolve) => {
      commit(types.SET_INSTAGRAM, instagram);
      resolve()
    })
  };

  export const setAddress = async ({ commit }, address) => {
    return new Promise((resolve) => {
      commit(types.SET_ADDRESS, address);
      resolve()
    })
  };

  export const setNumber = async ({ commit }, number) => {
    return new Promise((resolve) => {
      commit(types.SET_NUMBER, number);
      resolve()
    })
  };

  export const setComplement = async ({ commit }, complement) => {
    return new Promise((resolve) => {
      commit(types.SET_COMPLEMENT, complement);
      resolve()
    })
  };

  export const setAdvertiseDates = async ({ commit }) => {
    return new Promise((resolve) => {
      const newAdDate = {
        title: "",
        id: uuidv4(),
        description: "",
        disabled: false,
      };
      commit(types.SET_NEW_ADVERTISE, newAdDate);
      resolve()
    })
  };

  export const setBudget = async ({ commit }, budget) => {
    return new Promise((resolve) => {
      commit(types.SET_BUDGET, budget);
      resolve()
    })
  };
  export const setTechFeeValue = async ({ commit }, techfee) => {
    return new Promise((resolve) => {
      commit(types.SET_TECHFEVALUE, techfee);
      resolve()
    })
  };
  
  export const updateOrder = async (_, payload) => {
    const {orderId, data} =  payload
    return new Promise((resolve, reject) => {
      const auth = getAuth();
        const user = auth.currentUser;
        console.log('ORDER price:',data, orderId)
        if (user && user.uid) {
            const orderRef = doc(db, `orders/${orderId}`);
            updateDoc(orderRef,data).then(()=>{
             
            }).catch((err)=>{
              reject(err)
            })

        }
      resolve()
    })
  };
  export const setFinalPrice = async ({ commit }, finalPrice) => {

    return new Promise((resolve) => {
     
              commit(types.SET_FINALPRICE, finalPrice);


        
      resolve()
    })
  };

  export const setDestination = async ({ commit }, destination) => {
    return new Promise((resolve) => {
      commit(types.SET_DESTINATION, destination);
      resolve()
    })
  };

  export const setNewAdvertise = async ({ commit, state }, payload) => {
    console.log(state)
    //const companyName = state.googleAds.companyName;
    //const description = state.googleAds.description;
    if(payload == null){
      new Error('Profile is null')
    }
    const {companyname,description } = payload
    const newAd = {
      id: uuidv4(),
      title: "",
      keywords:[],
      description: "",
      disabled: false,
    };
    return new Promise((resolve) => {
      const prompt = `Como um especialista em Google Ads `+
      `crie um anúncio de search para empresa ${companyname} que trabalha com ${description}. `+
      `Não utilizar exclamação no final da descrição em no título. `+
      `Para o anuncio crie um título, uma descrição e keywords `+
      `Use o seguinte objeto JSON de exemplo para cada anúncio { "title": String, "keywords": Array, "description": String }. `+
      'O titulo deve ter no máximo 26 caracteres.'+
      `A descrição deve ser resumida com no máximo 80 caracteres. `+
      `Nunca exceder o limite de caractéres para cada título. `+
      `Nunca exceder o limite de caractéres nas descrições. ` +
      `Não precisa usar todos os elementos enviados, o importante é não exceder o limite de caracteres.` +
      `A resposta deve ser em JSON em formato de string. exemplo: {"title":String,"keywords":Array,"description":String} `;
     // `Liste a resposta em um objeto JSON com parâmetro title1 e description1 respectivamente sem ponto.`
      Window.$openai.createCompletion({
        model: "text-davinci-003",
        prompt: prompt, //`Como um especialista em Google Ads, criar um anúncio de search para empresa ${companyName} que trabalha com ${description} com 1 título com no máximo 26 caracteres cada e 1 descrição resumidas diferentes com no máximo 80 caracteres cada. Não utilizar exclamação no final. Nunca exceder o limite de caractéres para cada título. Nunca exceder o limite de caractéres nas descrições. Não precisa usar todos os elementos enviados, o importante é não exceder o limite de caractéres. Liste a resposta em um objeto JSON com parâmetro title1 e description1 respectivamente sem ponto.`,
        max_tokens: 700,
        temperature: 0.6,
        top_p: 0.9,
      }).then(completion => {
        console.log("Resposta do OpenAI", completion)
        const json = completion.data.choices[0].text.replace('\n','').replace('\\"','"')
        console.log("Resposta do json", json)
        let openAiRequest = JSON.parse(json)
        console.log('resposne ai', openAiRequest)
        newAd.title = openAiRequest.title
        newAd.keywords = openAiRequest.keywords
        newAd.description = openAiRequest.description
        commit(types.SET_NEW_ADVERTISE, newAd);
        resolve()
      })
    })
  };

  export const updateAdvertise = async ({ commit }, advertise) => {
    return new Promise((resolve) => {
      commit(types.UPDATE_ADVERTISE, advertise);
      resolve()
    })
  };
