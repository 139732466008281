<template>
  <div class="--fundo">
    <v-container>
      <div>
        <v-row class="d-flex flex-column --bottom">
          <v-col
            cols="12"
            md="12"
            class="d-flex flex-column justify-center text-center"
          >
            <h2 v-html="$t('fourthsection.title')" class="--h2"></h2>
          </v-col>
        </v-row>
        <v-row
          class="d-flex flex-md-row flex-column justify-space-between --bottom"
        >
        <v-col cols="12">
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(question, i) in questions" :key="i">
          <v-expansion-panel-header><b>{{ question.question }}</b></v-expansion-panel-header>
          <v-expansion-panel-content>{{ question.answer }}</v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  data() {
    return {
      questions: [
  {
    question: 'O que é o AI Online Ads do Canal do Anúncio?',
    answer: 'O AI Online Ads do Canal do Anúncio é uma ferramenta de criação de anúncios de Search do Google Ads que utiliza inteligência artificial para criar campanhas altamente eficazes e personalizadas em poucos minutos, sem a necessidade de conhecimentos técnicos avançados. A ferramenta também oferece recursos de automação para economizar tempo e esforço na criação e gestão de campanhas publicitárias no Google Ads.'
  },
  {
    question: 'Como faço para utilizar o AI Online Ads do Canal do Anúncio?',
    answer: 'Para utilizar o AI Online Ads do Canal do Anúncio, basta se cadastrar como usuário da ferramenta em nosso website e começar a criar seus anúncios de Search do Google Ads de maneira rápida e fácil. A ferramenta utiliza inteligência artificial e recursos de automação para criar campanhas altamente eficazes e personalizadas. Além disso, o AI Online Ads oferece suporte técnico e especialistas em marketing digital para ajudar você a maximizar o potencial dos seus anúncios no Google Ads.'
  },
  {
    question: 'Quais são os tipos de anúncios oferecidos pelo AI Online Ads?',
    answer: 'O AI Online Ads do Canal do Anúncio oferece diversos tipos de anúncios de Search do Google Ads, incluindo anúncios de texto, anúncios responsivos, anúncios de pesquisa dinâmicos e anúncios de aplicativos. A escolha do tipo de anúncio dependerá das necessidades específicas do seu negócio.'
  },
  {
    question: 'Quais são os requisitos para publicar anúncios no AI Online Ads?',
    answer: 'Para publicar anúncios no AI Online Ads, é necessário ter uma conta em nossa plataforma, uma página de destino relevante para os anúncios e um orçamento definido para investimento em publicidade. Além disso, é importante seguir as políticas de publicidade do Google Ads e criar anúncios relevantes e eficazes para o público-alvo.'
  },
  {
    question: 'Como eu posso acompanhar o desempenho dos meus anúncios?',
    answer: 'Para acompanhar o desempenhos dos seus anúncios, basta acessar a área de usuário em nossa plataforma e visualizar os resultados de cada campanha. Além disso, você pode acompanhar o desempenho dos seus anúncios no AI Online Ads através do painel de controle da sua conta.'
  },
  {
    question: 'Como faço para cancelar minha conta no AI Online Ads?',
    answer: 'Basta entrar em contato com nossa equipe e solicitar o cancelamento da sua conta. Após o cancelamento, você não poderá mais utilizar os serviços do AI Online Ads.'
  },
  {
    question: 'Quais os métodos de pagamento oferecidos pelo AI Online Ads?',
    answer: 'O AI Online Ads oferece diversos métodos de pagamento, incluindo cartão de crédito, boleto bancária, transferência bancária e PIX. Para mais informações, entre em contato com nossa equipe.'
  },
  {
    question: 'Quanto custa utilizar os serviços do AI Online Ads?',
    answer: 'Para utilizar a plataforma do AI Online Ads, é necessário pagar uma taxa de acréscimo de 20% do valor optado em sua assinatura.'
  },
  {
    question: 'O AI Online Ads oferece suporte ao cliente? Como eu posso entrar em contato?',
    answer: 'O AI Online Ads oferece suporte ao cliente através de e-mail, telefone e chat online. Para entrar em contato, basta acessar a área de suporte em nossa plataforma ou enviar um e-mail para contato@canaldoanuncio.com'
    }
  ]
    }
  }
}
</script>

<style scoped lang="scss">
.--h2 {
  font-weight: 700;
  font-size: 30px;
  padding-top: 4rem;
  color: #094949;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--form {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.--bottom {
  padding-bottom: 4rem;
}
.--fundo{
  background-image: url(../../assets/cover-fourth-section.png);
  background-size: 100%;
}
</style>
