import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueYouTubeEmbed from 'vue-youtube-embed'
import i18n from './plugins/i18n'
import store from "./store";
import {axios} from '../src/config'
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'

import { Configuration, OpenAIApi } from "openai";
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { db, Firebase } from './config/firebase'

Vue.use(VueYouTubeEmbed)
Vue.use(VueMask)
Vue.use(VueAxios, axios);

const configuration = new Configuration({
  organization: "org-Xldmv5FVHhyLxqTlkPYIX0iw",
  apiKey: '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' //process.env.OPENAI_API_KEY,
});
Window.$openai = new OpenAIApi(configuration);

const sandbox = window.location.href.includes("localhost") ? true : false;

// const firebaseConfig = {
//   apiKey: "AIzaSyBMVInhHnQU8HYZGMdQ93bcX2B0kNG4urs",
//   authDomain: "media-trade-ads.firebaseapp.com",
//   projectId: "media-trade-ads",
//   storageBucket: "media-trade-ads.appspot.com",
//   messagingSenderId: "384791703191",
//   appId: "1:384791703191:web:e5c820f33424b4911c83d2",
//   measurementId: "G-ESJ599HFP8"
// };

const pagarmeConfig = {
  api: 'ak_live_3c1AzFI0s2tSwt6wrijvFYzqiCwLLt',
  crypt: 'ek_live_dqGKlUEm5d37vQ0UAiJE30h3DYSPF9',
};
const pagarmeConfigTest = {
  api: 'ak_test_4DFtgNzc9IFyEFl8B3LJi79AarrmWf',
  crypt: 'ek_test_gVb6Nf7RxZBmw2ah2NddOwrsLGrLoX',
};

// const Firebase = initializeApp(firebaseConfig);
let app
let initialize = true

// Initialize Cloud Firestore and get a reference to the service
// const db = getFirestore(Firebase);
// const auth = getAuth(app);

Vue.prototype.$db = db
// Vue.prototype.$auth = auth;
Vue.prototype.$firebase = Firebase;
Vue.config.productionTip = false
Vue.prototype.$pagarmeConfig = sandbox ? pagarmeConfigTest : pagarmeConfig;

const auth = getAuth();
onAuthStateChanged(auth, (user) => {

  Vue.prototype.$auth = auth;

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    Vue.prototype.$user = user;
    Vue.prototype.$isAuth = !!user;

  
  } else {
    Vue.prototype.$user = {};
    Vue.prototype.$isAuth = false;
  }

  if (!app && initialize){
    app = new Vue({
    data() {
      return {
        isMobile: false,
        // isAuth: !!user,
        user: user,
        // auth: auth,
      }
    },
    beforeDestroy() {
      if (typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    created() {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
      // document.getElementById('page-preloader').remove();
    },
    methods: {
      onResize() {
        this.isMobile = window.innerWidth < 600
      },
      remove() {}
      
    },
    router,
    vuetify,
    i18n,
    store,
    axios,
  
    render: h => h(App)
  }).$mount('#app')
  }
});



// new Vue({
//   data() {
//     return {
//       isMobile: false,
//       db: db
//     }
//   },
//   beforeDestroy() {
//     if (typeof window === 'undefined') return
//     window.removeEventListener('resize', this.onResize, { passive: true })
//   },
//   created() {
//     this.onResize()
//     window.addEventListener('resize', this.onResize, { passive: true })
//     // document.getElementById('page-preloader').remove();
//   },
//   methods: {
//     onResize() {
//       this.isMobile = window.innerWidth < 600
//     },
//     remove() {}
    
//   },
//   router,
//   vuetify,
//   i18n,
//   store,
//   axios,

//   render: h => h(App)
// }).$mount('#app')