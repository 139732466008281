<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-column justify-center text-center"
        >
          <h1 v-html="$t('where.title')" class="--h1 pt-12"></h1>
          <p v-html="$t('where.subtitle')" class="--p"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            dense
            v-model="address.zipcode"
            label="Insira seu CEP"
            outlined
            @blur="confirmCep"
            append-icon="mdi-map-search"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            dense
            v-model="address.provincy"
            label="Estado"
            disabled
            outlined
          ></v-text-field>
          <v-text-field
            dense
            v-model="address.city"
            label="Cidade"
            disabled
            outlined
          ></v-text-field>
          <v-text-field
            dense
            v-model="address.number"
            label="Número"
            outlined
            @keyup="setNumber(address.number)"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            dense
            v-model="address.street"
            label="Rua"
            disabled
            outlined
          ></v-text-field>
          <v-text-field
            dense
            disabled
            v-model="address.neighborhood"
            label="Bairro"
            outlined
          ></v-text-field>
          <v-text-field
            dense
            v-model="address.complement"
            label="Complemento"
            @keyup="setComplement(address.complement)"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            dark
            :loading="loading"
            color="#1ab873"
            block
            @click="sendData()"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import cep from "cep-promise";
export default {
  name: "newaddress-component",
  data() {
    return {
      loading: false,
      address: {
        zipcode: "",
        provincy: "",
        city: "",
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
      },
    };
  },

  methods: {
    confirmCep() {
      cep(this.address.zipcode)
        .then((address) => {
          console.log("request zipcode", address);
          this.address.provincy = address.state;
          this.address.city = address.city;
          this.address.street = address.street;
          this.address.neighborhood = address.neighborhood;

          // send address to store
          this.setAddress(this.address);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setAdress(value) {
      switch (value) {
        case "state":
          this.state = value;
          break;
        case "city":
          this.city = value;
          break;
        case "street":
          this.street = value;
          break;
        case "neighborhood":
          this.neighborhood = value;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.--title {
  font-weight: 700;
  color: #000000;
}
.--subtitle {
  font-weight: 700;
  color: #000000;
}
.--p {
  padding: 2rem;
}
.--anchor {
  color: #509e9e;
}
@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
