<template>
  <v-footer>
    <div class="block" style="width: 100%">
      <v-container>
        <div class="d-flex justify-space-between flex-column flex-md-row">
          <div style="min-width: 20%; padding-top: 45px;">
            <a href="https://www.themediatrade.com" target="_blank"><v-img
              alt="The Media Trade Logo"
              contain
              src="@/assets/images/logo.svg"
              max-width="100"
              aspect-ratio="1"
            ></v-img>
            </a>
            <div
              class="text-caption text--secondary mt-3 --text"
              style="max-width: 200px"
            >
              <span class="--text">© 2015-2022 The Media Trade<br />23.659.903/0001-84<br /></span>
            </div>
            <div class="d-flex pt-9">
              <a
                href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fthemediatrade.com"
                target="_blank"
              >
                <v-img
                  src="@/assets/images/seal-google-safe-browsing.svg"
                  height="40"
                  width="100"
                  contain
                ></v-img>
              </a>
              <a href="javascript:ss_open_sub()">
                <v-img
                  src="@/assets/images/seal-alpha-ssl.svg"
                  height="40"
                  width="40"
                  contain
                ></v-img>
              </a>
            </div>
          </div>
          <div class="py-6">
            <v-list flat dense style="background: none !important">
              <v-subheader class="text-h6 text--primary ml-2"
                ><span class="--text">The Media Trade</span>
              </v-subheader>
              <v-list-item-group v-model="menu">
                <v-list-item
                  target="_blank"
                  href="https://themediatrade.com/sobre"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text" v-html="$t('footer.about')"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank"
                  href="https://themediatrade.com/trabalhe-conosco/"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text" v-html="$t('footer.workwithus')"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank"
                  href="https://themediatrade.com/investidores"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text" v-html="$t('footer.investors')"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank"
                  href="https://themediatrade.com/fale-conosco"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text" v-html="$t('footer.talktous')"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="py-6">
            <v-list flat dense style="background: none !important">
              <v-subheader class="text-h6 text--primary ml-2"
                ><span class="--text">Soluções</span>
              </v-subheader>
              <v-list-item-group v-model="menu">
                <v-list-item target="_blank" href="https://canaldoanuncio.com">
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text">Canal do Anúncio</span>
                      <v-icon small right class="--text">mdi-arrow-top-right</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item target="_blank" href="https://canaldomidia.com">
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text">Canal do Mídia</span>
                      <v-icon small right class="--text">mdi-arrow-top-right</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank"
                  href="https://franquias.canaldoanuncio.com"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text" v-html="$t('footer.franchise')"></span>
                      <v-icon small right class="--text">mdi-arrow-top-right</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  target="_blank"
                  href="https://app.canaldoanuncio.com/portal-do-veiculo"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="--text" v-html="$t('footer.mediavendors')"></span>
                      <v-icon small right class="--text">mdi-arrow-top-right</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="py-6">
            <v-list flat dense style="background: none !important">
              <v-subheader class="text-h6 text--primary ml-2"
                ><span class="--text" v-html="$t('footer.followus')"></span>
              </v-subheader>
              <v-list-item-group v-model="menu">
                <v-list-item
                  href="https://www.linkedin.com/company/media-trade"
                  target="_blank"
                >
                  <v-list-item-icon>
                    <v-icon dark>mdi-linkedin</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content small class="social-media">
                    <v-list-item-title
                      ><span class="--text">LinkedIn</span>
                      <v-icon small class="ml-6 --text">mdi-arrow-top-right</v-icon>
                    </v-list-item-title>
                  </v-list-item-content> </v-list-item
                >
                <v-list-item>
                  <v-list-item-content>
                    <v-btn
                      x-large
                      color="white"
                      class="mt-12 px-9"
                      light
                      href="https://store.themediatrade.com"
                      target="_blank"
                      ><v-icon left class="ml-n6">mdi-shopping</v-icon>The Media
                      Store

                      <v-icon small right class="mr-n6"
                        >mdi-arrow-top-right</v-icon
                      ></v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </v-container>
      <!-- <v-divider class="my-6"></v-divider>
      <v-container>
        <div class="d-flex mb-3" style="font-size: 0.75em">
          <router-link to="/policitas-de-cookie-e-privacidade">Privacy Policy            
          </router-link>
          <v-divider vertical class="mx-6"></v-divider>
          <router-link to="/policitas-de-cookie-e-privacidade">Terms and Conditions</router-link>
          <v-spacer></v-spacer>
        </div>
      </v-container> -->
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      menu: null,
    };
  },
};
</script>

<style scoped lang="scss">
.--title {
  font-weight: 600;
  font-size: 47px;
  line-height: 4rem;
}

.--text {
  color: white
}

</style>