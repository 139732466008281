import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBMVInhHnQU8HYZGMdQ93bcX2B0kNG4urs",
    authDomain: "media-trade-ads.firebaseapp.com",
    projectId: "media-trade-ads",
    storageBucket: "media-trade-ads.appspot.com",
    messagingSenderId: "384791703191",
    appId: "1:384791703191:web:e5c820f33424b4911c83d2",
    measurementId: "G-ESJ599HFP8"
  };

  const Firebase = initializeApp(firebaseConfig);
  const db = getFirestore(Firebase);

  export { db, Firebase }