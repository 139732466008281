<template>
  <v-app>
    <v-app-bar app color="#DAF7F7" hide-on-scroll elevation="0">
      <div class="d-flex align-center">
        <a @click="goHome()"
          ><v-img
            alt="Logo CDM"
            class="shrink mr-2"
            contain
            src="../src/assets/canal-do-anuncio.svg"
            transition="scale-transition"
            width="145"
        /></a>
      </div>

      <v-spacer></v-spacer>

      <div class="pr-2">
        <v-btn @click="dialog = true" color="#333333" outlined>
          <span class="mr-2" v-html="$t('header.startbutton')"></span>
        </v-btn>
      </div>

      <div class="pl-2">
        <template v-if="!$isAuth">
          <v-btn @click="logIn()" color="#14a3a3">
            <span
              class="mr-2"
              style="color: white"
              v-html="$t('header.signinbutton')"
            ></span>
          </v-btn>
        </template>
        <template v-if="$isAuth">
          <v-btn @click="logOff()" color="#14a3a3">
            <span
              class="mr-2"
              style="color: white"
              v-html="$t('header.signoutbutton')"
            ></span>
          </v-btn>
        </template>
      </div>

      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark text v-bind="attrs" v-on="on"
              ><v-img
                :src="langs[$i18n.locale]"
                height="20"
                width="30"
                contain
              ></v-img>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(lang, i) in langs"
              :key="i"
              @click="changeLang(i)"
            >
              <v-list-item-title class="d-flex align-center"
                ><v-img
                  :src="lang"
                  height="14"
                  width="21"
                  class="mr-3"
                  contain
                ></v-img
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-dialog v-model="dialog" max-width="1000">
      <PopupForm @popupform:close="dialog = false" />
    </v-dialog>

    <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import AppFooter from "./components/footer/AppFooter.vue";
import PopupForm from "./components/forms/PopupForm.vue";
import { signOut } from "firebase/auth";
// import LoginForm from "./components/forms/LoginForm.vue";

export default {
  name: "App",
  components: {
    AppFooter,
    PopupForm,
    // LoginForm
  },

  data() {
    return {
      dialog: false,
      langs: {
        "en-US": require("@/assets/images/countries/en-US.svg"),
        "pt-BR": require("@/assets/images/countries/pt-BR.svg"),
        "es-ES": require("@/assets/images/countries/es-ES.svg"),
      },
      isMobile: window.innerWidth < 600,
    };
  },

  props: {
    position: String,
    hideOnScroll: Boolean,
    dark: Boolean,
  },
  created() {
    const localeDefault = this.$i18n.locale;
    const locale = this.getCookie("TMT[lang]");
    if (locale != localeDefault && locale) {
      this.changeLang(locale);
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.setCookie("TMT[lang]", lang, 180);
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    logIn() {
      this.$router.push("/login");
    },

    logOff() {
      signOut(this.$auth)
        .then(() => {
          if (this.$route.path != "/") this.$router.push("/");
          window.location.reload(); // recarrega a página
        })
        .catch((error) => {
          console.log(error);
        });
    },

    goHome() {
      if (this.$route.path != "/") this.$router.push("/");
      this.$router.push("/");
    },
  },
  computed: {
    styling() {
      if (this.dark) return "background: #233a75; height: 70px";
      else return "background: #ffffff; height: 70px";
    },
  },
};
</script>

<style lang="scss">
*,
.v-application * {
  font-family: "Poppins", sans-serif !important;
  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .text-subtitle-1,
  .text-subtitle-2,
  .text-body-1,
  .text-body-2,
  .text-button,
  .text-caption,
  .text-overline {
    font-family: "Poppins", sans-serif !important;
  }
}

.v-application .v-application--wrap a:not(.v-btn) {
  text-decoration: none;
  color: #ffffff;
}
.v-btn {
  letter-spacing: normal !important;
  text-transform: none !important;
  &:not(.v-btn--text) {
    font-weight: light;
  }
}
.hero {
  background: #ffffff;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  position: relative;
  margin: 0;
}
.logo {
  min-width: 63.5px;
  background: #ffffff;
  width: 70px;
  height: 75px !important;
  border: none;
  padding: 0;
  min-width: 63.5px !important;
  &:before {
    background: none;
  }
  &.v-btn--active::before {
    opacity: 0 !important;
  }
}
.theme--light.v-application,
.theme--dark.v-application {
  background: #ffffff !important;
}

.v-footer {
  background: #333333 !important;
}
.vh-100 {
  min-height: 100vh;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px;
}
.v-overlay--active .v-overlay__scrim {
  opacity: 0.7 !important ;
  background-color: rgb(10, 10, 10) !important ;
  border-color: rgb(10, 10, 10) !important ;
  backdrop-filter: saturate(180%) blur(20px) !important ;
}

.--menu {
  flex: 1 1 50%;
}
</style>
