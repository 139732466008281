<template>
  <v-container class="pr-16 pl-16">
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="d-flex flex-column justify-center text-center"
      >
        <h1 v-html="$t('hours.title')" class="--h1"></h1>
        <p v-html="$t('hours.subtitle')" class="--p"></p>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column">
      <v-col>
        <matrix v-for="(m, index) in matrix" :matrix="m" :key="index"></matrix>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="black" block @click="addMatrix()">
          <span style="color: white">Adicionar dia e hora</span>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn :loading="loading" color="#1ab873" block @click="sendData()">
          <span style="color: white">Continuar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pb-16"></v-row>
  </v-container>
</template>

<script>
import Matrix from "@/components/hours/Matrix.vue";

export default {
  components: { Matrix },
  name: "hours-page",

  data() {
    return {
      loading: false,
      matrix: [],
    };
  },
  props: {
    scrollPosition: Number,
    snap: Object,
  },
  created() {},
  mounted() {},
  destroyed() {},

  methods: {
    sendData() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/price");
      }, 5000);
    },
    addMatrix() {
      const MATRIX = {
        days: [
          "Todos os dias",
          "Segunda a Sexta",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sábado",
          "Domingo",
        ],
        starthour: "",
        finalhour: "",
      };
      this.matrix.push(MATRIX);
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 117px;
  padding-bottom: 118px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
