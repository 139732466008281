<template>
  <div>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="d-flex flex-column justify-center text-center"
          >
            <h1 v-html="$t('payment.title')" class="--h1"></h1>
            <p v-html="$t('payment.subtitle')" class="--p"></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined class="pa-2">
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <p
                      v-html="$t('payment.billingaddress')"
                      class="--adstitle"
                    ></p>
                    <p v-html="$t('payment.needfully')"></p>
                  </v-col>
                  <v-col cols="6"></v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col cols="12" md="6" xs="12">
                    
                    <v-text-field
                    dense
                    v-model="address.zipcode"
                    label="Insira seu CEP"
                    outlined
                    @blur="confirmCep"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="12"  md="8" xs="12">
                              <v-text-field
                              v-model="address.street"
                              hide-details
                              label="Rua"
                              disabled
                              outlined
                              dense
                              ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" xs="12">
                              <v-text-field
                              v-model="address.neighborhood"
                              label="Bairro"
                              hide-details
                              disabled
                              outlined
                              dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                   

                  </v-col>
                  <v-col cols="12" md="6" xs="12" class="justify-end">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-text-field
                              v-model="address.state"
                              label="Estado"
                              disabled
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                            <v-text-field
                              v-model="address.city"
                              label="Cidade"
                              hide-details
                              disabled
                              outlined
                              dense
                            ></v-text-field>
                      </v-col>
                   </v-row>
                   <v-row>
                        <v-col cols="12" md="4">
                    <v-text-field
                    v-model="address.street_number"
                    label="*Número"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="8">
                  <v-text-field
                    v-model="address.complement"
                    hide-details
                    label="Complemento"
                    outlined
                    dense
                  ></v-text-field>
                   <!-- {{ googleAds.address.street }},  {{ googleAds.address.street_number}},  {{ googleAds.address.neighborhood }} -  {{ googleAds.address.state }} -  {{ googleAds.address.city }} -  {{ googleAds.address.zipcode }}-->
                  </v-col>
                  </v-row>
                    <!-- <v-text-field
                      append-icon="mdi-pencil"
                      v-model="name"
                    ></v-text-field>
                    <v-text-field
                      append-icon="mdi-pencil"
                      v-model="street"
                    ></v-text-field>-->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined class="pa-2">
              <v-card-text>
                <v-row>
                  <v-col> <p class="--adstitle">2. Resumo do pedido</p></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="d-flex justify-space-between">
                      <div><p>Anúncios</p></div>
                      <div>
                        <p>{{ advertisesCount }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div><p>Taxa de gestão</p></div>
                      <div><p>{{ googleAds.techFeeDisplay }} ({{ $n(googleAds.techFeeValue, "currency") }})</p></div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div><p>Campanha</p></div>
                      <div>
                        {{ $n(googleAds.budget, "currency") }}
                      </div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div><p class="--adstitle">Total a ser pago</p></div>
                      <div>
                        <v-chip dark dense color="#14a3a3">{{
                          $n(googleAds.finalPrice, "currency")
                        }}</v-chip>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col> <p class="--adstitle">3. Pagamento</p></v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col>
                    <CreditCard :orderid="id" :isloading="loading" :block-button="!(address.zipcode && address.street_number)" @confirm-payment="sendToPay"></CreditCard>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </div>
</template>

<script>
import CreditCard from "../components/checkout/CreditCard.vue";
import { getFirestore, updateDoc, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import cep from "cep-promise";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "payment-page",
  components: {
    CreditCard,
  },

  data() {
    return {
      db: null, // define a instância do Firestore como nula
      error: false,
      loading: false,
      price: "",
      name: "",
      street: "",
      statusTitle: false,
      colorTitle: "",
      colorTitle1: "",
      colorTitle2: "",
      advertises: [],
      advertisesCount: 0,
      cpf: '11111111111',
      adsBudget: null,
      address: {
        zipcode: '',//"04151090",
        state: "",
        city: "",
        street: "",
        neighborhood: "",
        street_number: '',//"1231",
        complement: "",
        country: "br"
      },
      order:null
    };
  },
  props: {
    scrollPosition: Number,
    id: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters("googleAds",["budget", "googleAds"])
  },

  created() {
    this.confirmCep() //remover depois
    this.$db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;
    const docRef = doc(this.$db, `profile/${user.uid}`);
    // const orderRef = doc(this.$db, `orders/${this.id}}`);

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          // Extrai o valor do dado do documento
          const name = doc.data().name;
          const street = doc.data().street;
          const adsBudget = doc.data().adsBudget;
          const finalPrice = doc.data().finalPrice;
          // const advertises = doc.data().advertises || [];

          // Atualiza a propriedade do componente com o valor do dado
          this.name = name;
          this.street = street;
          this.adsBudget = adsBudget;
          this.finalPrice = finalPrice;
          // this.advertises = advertises;
          // this.advertisesCount = advertises.length;
          this.loadPaymentOrders();
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar o documento: ", error);
      });
      this.loadAddress()
      this. loadPaymentOrders()
  },

  destroyed() {},
  watch:{
  },

  methods: {
    ...mapActions("googleAds",["setAddress","setFinalPrice","setBudget","setTechFeeValue"]),
    async sendData() {
      if (!this.$db) {
        console.error("Objeto this.$db não definido.");
        return;
      }
      this.loading = true;
      const auth = getAuth();
      const user = auth.currentUser;
      // Verifica se o objeto user está definido e tem a propriedade uid
      if (!user || !user.uid) {
        console.error("Usuário não autenticado.");
        return (this.error = true);
      }

      const budget = parseInt(this.budget);

      try {
        // Cria referência ao documento de endereço do usuário no Firestore
        const budgetRef = doc(this.$db, `profile/${user.uid}`);

        // Salva os dados de endereço no documento de endereço
        await updateDoc(budgetRef, {
          budget,
        });

        // Redireciona para a próxima página
        this.$router.push("/payment");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    loadAddress(){
      const auth = getAuth();
      const user = auth.currentUser;
      const addressRef = doc(this.$db, `address/${user.uid}`);
      getDoc(addressRef)
        .then((doc) => {
          const addressData = doc.data()
         // console.log("Address", addressData)
          this.setAddress(addressData)
        }) .catch((error) => {
          console.error("Erro ao buscar o endereço: ", error);
        });

    },
    loadPaymentOrders() {

      console.log("LoadPaymentOrder", this.id, typeof this.id)
      this.$db = getFirestore();
      const orderRef = doc(this.$db, `orders/${this.id}`);

      getDoc(orderRef)
        .then((doc) => {
        //  console.log("doc", doc.data())
          if (doc.exists()) {
            const data = doc.data()
            this.order = data
            // Extrai o valor do dado do documento
            // const name = doc.data().name;
            // const street = doc.data().street;
            // const adsBudget = doc.data().adsBudget;
            // const finalPrice = doc.data().finalPrice;
            const advertises = doc.data().advertises || [];
            this.setFinalPrice(data.finalPrice)
            this.setBudget(data.budget)
            this.setTechFeeValue(data.techFeeValue)
           // this.setFinalPrice(doc.finalPrice)

            // Atualiza a propriedade do componente com o valor do dado
            // this.name = name;
            // this.street = street;
            // this.adsBudget = adsBudget;
            // this.finalPrice = finalPrice;
            this.advertises = advertises;
            this.advertisesCount = advertises.length;
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar o documento: ", error);
        });
    },
    async sendToPay(data){
  console.log('event data ', data)
      this.loading = true
      const auth = getAuth();
      const user = auth.currentUser;
      const profileRef = doc(this.$db, `profile/${user.uid}`);
     // await updateDoc(profileRef,{ cpf: this.cpf})
      
      const profileDoc = await getDoc(profileRef)
      const profile =  profileDoc.data();

     // console.log('profile', profile)
      
      if(!profile.document){
        throw new Error('Cpf inválido')
      }

      data.card.expirationDate = data.card.month+''+data.card.year
      const card = data.card
      let preAddress = this.address
      preAddress.zipcode = String(preAddress.zipcode).replace(/\D/g, "")
      if(this.address.complement){
      preAddress.complementary = this.address.complement
    }
     delete preAddress.complement

      let send = { 
        date: moment().format('YYYY-MM-DD HH:mm'),
        payload:{
        amount: this.googleAds.finalPrice,
        card, 
        method:'credit_card', 
        customer: { 
          external_id: user.uid, 
          name: profile.name,
          email: user.email,
          type: 'individual',
          documents:[
            {
              type: 'cpf',
              number: String(profile.document).replace(/\D/g, "") //only numbers
            }
          ],
          phone_numbers:[
            String(profile.phone).replace(' ','').replace('-','')
          ],
          country:'br'
        }, 
        address: preAddress, 
        orderId: this.id,
        dealId: this.order && this.order.zohoDealId ? this.order.zohoDealId : null,
        hostname: window.location.hostname 
        }
      }
      const Form = {
                url: "",
                payload: send //this.paymentForm
            }
    //  if (this.paymentType == 'credit_card') {
          Form.url = `https://us-central1-media-trade-ads.cloudfunctions.net/pagarmePaymentCreditCard`
          //Form.url = `http://127.0.0.1:5001/media-trade-ads/us-central1/pagarmePaymentCreditCard`
     // }
      // if (this.paymentType == 'voucher') {
      //     Form.url = `https://us-central1-${config.projectId}.cloudfunctions.net/pagarmePaymentBoleto`
      // }
      console.log('Form', Form)
      let formAddress = this.address
      this.$http.put(`/labs/aioads/address`, formAddress).then(()=>{
        
           updateDoc(profileRef, { address: this.address}).then(()=>{
           // this.sendOrderToZoho()
            this.sendPaymentRequest(Form).then(()=>{

              this.loading = false;
              this.$router.push("/resume");
            })
           }).catch((err)=>{
            console.log('Error on pay: ', err)
           })

        }).catch((err)=>{
          this.loading = false;
          console.log(err)
        })
      

    },
  async  sendPaymentRequest(Form){
    return await this.axios.post(Form.url, Form.payload)
          .then((data) => {
              console.log('data', data)
              this.sendOrderToZoho()
              //this.$router.push("/resume");
          })
          .catch((error) => {
              console.error('error', error)
          })
          // .finally(() => {
          //     this.paying = false
          // });
    },
    async sendOrderToZoho(){
      const orderRef = doc(this.$db, `orders/${this.id}`);
      const orderDoc = await getDoc(orderRef);
      const orderData = orderDoc.data();
    console.log('before send data',orderData)
      this.$http.post('/labs/aioads/order',{ moduleData: orderData})
          .then((res) => {
              console.log('data', res.data.data)
              console.log('PortentialID: ', res.data.data.PotentialId)
              if(res.data.data.PotentialId){
                console.log('PortentialID: ', res.data.data.PotentialId)
                if(this.updateOrderData(this.id,{
                  zohoDealId: res.data.data.PotentialId,
                  finalPrice: this.googleAds.finalPrice
                } )){

                 // this.$router.push("/resume");
                }
              }
          })
          .catch((error) => {
              console.error('error', error)
          })
          // .finally(() => {
          //     this.paying = false
          // });
    },
    async updateOrderData(orderId, data){
      const orderRef = doc(this.$db, `orders/${orderId}`);
     await  updateDoc(orderRef,data).then((res)=>{
        console.log('Update potential id in iordr', res)
        return res
      })
      return null
    },
    confirmCep() {
      cep(String(this.address.zipcode).replace(/\D/g, ""))
        .then((address) => {
          this.address.state = address.state;
          this.address.city = address.city;
          this.address.street = address.street;
          this.address.neighborhood = address.neighborhood;

          // send address to store
          this.setAddress(this.address);
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
    rollBack() {
      setTimeout(() => {
        this.$router.push("/price");
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.--h1 {
  font-weight: 700;
  font-size: 40px;
  padding-top: 1rem;
  color: #333333;
}
.--p {
  padding-top: 2rem;
  font-size: 18px;
  color: #333333;
}
.--adstext {
  font-size: 14px;
  color: #333333;
}
.--adstitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.--subtitle {
  font-weight: 400;
}
.--bottom {
  padding-bottom: 4rem;
}

.--form {
  padding-top: 117px;
  padding-bottom: 118px;
}

@keyframes changewidth {
  0% {
    bottom: -2px;
  }
  50% {
    bottom: -2px;
  }
  100% {
    bottom: 28px;
  }
}
</style>
